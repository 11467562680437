import React from 'react';
import {Box, InputAdornment} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ShortTextIcon from '@mui/icons-material/ShortText';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from '../../../../../config/i18n';
import {
  Container,
  Header,
  useClasses,
  BlackShade,
  Content,
  InputBox,
  ArtistInput,
  DragArea,
} from './style';
import Typography from '../../../../atoms/Typography';
import Image from '../../../../atoms/Image';
import {colors} from '../../../../../config/colors';
import TextField from '../../../../atoms/TextField'
import Button from '../../../../atoms/Button'

const BandCamp = () => {

 const { t } = useTranslation('common', 'landing', 'howItWorks');
 const classes = useClasses();

    return (
      <Container>
        <Header image="/images/home/background.png">
          <BlackShade>
            <Box>
              <Typography variant="overline" className="dateTitle" gutterBottom>
                {t('howItWorks:band_camp_date')}
              </Typography>
              <Typography variant="h4" className="dateTitle" gutterBottom>
                {' '}
                {t('howItWorks:band_camp')} 2021
              </Typography>
              <Typography variant="caption" color="secondary" gutterBottom>
                {' '}
                {t('howItWorks:by_african_org')}
              </Typography>
            </Box>
            <Image
              width="62px"
              height="62px"
              src="/images/home/AvatarWhite.png"
              alt="AvatarWhite"
            />
          </BlackShade>
        </Header>
        <Content>
          <Typography variant="subtitle1" color='secondary' gutterBottom>
            {t('howItWorks:answer_a_few_question')}
            <EditIcon fontSize="small" color="primary" />
          </Typography>
          <ArtistInput>
            <TextField
              disabled
              placeholder={t('howItWorks:group_artist')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ShortTextIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
          </ArtistInput>

          <InputBox>
            {' '}
            <TextField
              disabled
              placeholder={t('common:name')}
              className={classes.Inputs}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ShortTextIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />{' '}
            <TextField
              disabled
              placeholder={t('common:email')}
              className={classes.Inputs}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />{' '}
            <TextField
              disabled
              placeholder={t('common:addres')}
              className={classes.Inputs}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOnIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
          </InputBox>
        </Content>
        <DragArea>
          <Box className={classes.DashedBox1}>
            <Box className={classes.DashedBox2}>
              <Typography variant="h5" color="primary" gutterBottom>
                {t('howItWorks:start_building_form')}
              </Typography>
              <Typography variant="caption" align="center">
                {t('howItWorks:drag_drop_elements')}
              </Typography>
            </Box>
          </Box>
        </DragArea>
        <Box className={classes.ButtonBox}>
          <Button startIcon={<AddIcon />} variant="text" color="primary">
            {t('howItWorks:add_terms_conditions')}
          </Button>
        </Box>
      </Container>
    );
}

export default BandCamp;