import styled from 'styled-components';
import {Box} from '@mui/material';
import {makeStyles} from '@mui/styles';
import Card from '../../../../atoms/Card';
import {deviceSizes} from '../../../../../config/devices'

export const FiltersContainer = styled.div`
  position: relative;
  z-index: 1;
 

`;

export const StyledCard = styled(Card)`
  position: relative;
  width: 70%;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  margin-left: 60px;
  h5 {
    padding: 10px 25px;
  }
  @media (max-width: ${deviceSizes.tablet}) {
    margin-left: 40px;
    width: 80%;
  }
  @media (max-width: ${deviceSizes.sm}) {
   
    width:70%;
  }
`;

export const SearchBox = styled(Box)`
  && {
    background-color: ${({ theme }) => theme.secondary.light};
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 25px;
    margin: 0 20px;
    color: ${({ theme }) => theme.secondaryColor[700]};
    svg {
      fill: ${({ theme }) => theme.secondaryColor[700]};
    }
  }
`;

export const StyledItem = styled(Box)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.secondaryColor[150]};
    color: ${({ theme }) => theme.secondaryColor[700]};
    svg {
      fill: ${({ theme }) => theme.secondaryColor[700]};
    }
  }
`;

export const StyledTypographyBox = styled(Box)`
  && {
    background-color: ${({ theme }) => theme.secondary.light};
    color: ${({ theme }) => theme.secondaryColor[600]};
    padding: 10px 50px;
    border-radius: 6px;
    margin: 25px;
    text-align: center;
     @media (min-width: ${deviceSizes.md}) and (max-width: ${deviceSizes.lg}){
       margin:15px;
     }
  }
`;

export const useClasses = makeStyles({
  OpportunytyEllipseFilters: {
    position: 'absolute',
    right: '5%',
    top: '90px',
    [`@media(min-width: ${deviceSizes.md}) and (max-width: ${deviceSizes.lg})`]: {
      right: '0%',
      top: '40px'
    },
    [`@media(max-width: ${deviceSizes.sm})`]:{
      right: '8%'
    },
    [`@media(min-width: ${deviceSizes.sm}) and (max-width: ${deviceSizes.tablet})`]: {
      right: '-5%',
      top: '-50px'
    },
  },
  
});
