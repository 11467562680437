import { gql } from '@apollo/client';




export const VERIFY_ACCOUNT = gql`
  mutation verifyAccount($input: VerifyAccountInput) {
    verifyAccount(input: $input) {
      status
      message
    }
  }
`;

export const REQUEST_VERFICATION = gql`
  mutation requestVerification {
    requestVerification {
      status
      message
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($input: ChangePassword) {
    updatePassword(input: $input) {
      status
      message
    }
  }
`;
