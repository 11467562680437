import { gql } from '@apollo/client';

export const CREATE_OPPORTUNITY_MUTATION = gql`
  mutation($opportunityInput: NewOpportunityInput) {
    createOpportunity(input: $opportunityInput) {
      id
      title
      slug
      description
      logo {
        name
        path
        thumb
      }
    }
  }
`;

export const UPDATE_OPPORTUNITY_MUTATION = gql`
  mutation($id: ID!, $opportunityInput: UpdateOpportunityInput!) {
    updateOpportunity(id: $id, input: $opportunityInput) {
      id
      title
      slug
      opp_status
    }
  }
`;

export const CREATE_APPLICATION_MUTATION = gql`
  mutation($applicationInput: NewApplicationInput) {
    createApplication(input: $applicationInput) {
      id
      status
      # code
      # opportunity
      # user
      # rate
      # user_rate
      # sections
      # created_at
      # payment_status
      # refunded_at
    }
  }
`;

export const UPDATE_APPLICATION_MUTATION = gql`
  mutation($id: ID!, $applicationInput: UpdateApplicationInput!) {
    updateApplicationData(id: $id, input: $applicationInput) {
      id
      status
      # code
      # opportunity
      # user
      # rate
      # user_rate
      # sections
      # created_at
      # payment_status
      # refunded_at
    }
  }
`;

export const UPDATE_FORM_MUTATION = gql`
  mutation($id: ID!, $input: ApplicationFormBuilderInput!) {
    updateOpportunityForm(id: $id, input: $input) {
      id
      title
      slug
    }
  }
`;

export const UPDATE_PAYMENT_MUTATION = gql`
  mutation updateOpportunityPayment ($id: ID!, $input: UpdatePaymentFormInput!) {
    updateOpportunityPayment(id: $id, input: $input) {
      id
      title
      slug
      target
      experience_level
      deadline_date
      start_date
      end_date
      created_at
      max_age
      min_age
      is_paid
    }
  }
`;

export const DELETE_OPPORTUNITY = gql`
  mutation deleteOpportunity($id: ID!) {
    deleteOpportunity(id: $id) {
      id
      title
      slug
    }
  }
`;

export const UPLOAD_FILES = gql`
  mutation uploadFiles($files: [Upload!]!) {
    uploadFiles(files: $files) {
      name
    path
    full_path
    placeholder
    }
  }
`;

export const CLONE_OPPORTUNITY = gql`
  mutation cloneOpportunity($slug: String!) {
    cloneOpportunity(slug: $slug) {
      summary
      description
    }
  }
`;