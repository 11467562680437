import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import { CardMedia as MuiCardMedia } from '@mui/material';
import { rgba } from 'polished';
import { deviceSizes } from '@app/config/devices';
import Card from '../../../../atoms/Card';

export const Container = styled(Card)`
   @media(max-width: ${deviceSizes.md}){
     display: none;
   }
`;

export const Header = styled(MuiCardMedia)`
  display: flex;
  flex-basis: 100%;
`;

export const BlackShade = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 70px;
  height: 100%;
  .dateTitle{
    color: #ffffff;
  }
  ${({ theme }) =>
    theme &&
    `
    background-color:${rgba(theme.common.black, 0.35)};
    `}

  background: linear-gradient(180deg, rgba(33, 33, 33, 0) 0%, rgba(49, 47, 47, 0.75) 100%), rgba(33, 33, 33, 0.35);
`;

export const Content = styled.div`
  padding: 40px 70px;
  border-bottom: 1px solid ${({ theme }) => theme.secondaryColor[150]};
`;

export const ArtistInput = styled.div`
  svg {
    fill: ${({ theme }) => theme.secondaryColor[150]};
  }
`;

export const InputBox = styled.div`
  display: flex;
  justify-content: space-between;
  svg {
    fill: ${({ theme }) => theme.secondaryColor[150]};
  }
`;

export const DragArea = styled.div`
  padding: 40px 70px;
  border-bottom: 1px solid ${({ theme }) => theme.secondaryColor[150]};
`;

export const useClasses = makeStyles({
  Inputs: {
    width: '31%',
  },
  DashedBox1: {
    border: `1px dashed  rgba(25, 25, 25, 0.32);`,
    padding: '5px',
  },
  DashedBox2: {
    backgroundColor: `#FAFAFA`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '40px 100px',
  },
  ButtonBox: {
    padding: '20px 50px',
  },
});
