import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import Image from '../../../atoms/Image';
import { deviceSizes } from '../../../../config/devices';
import Link from '../../../atoms/Link'

export const FooterContainer = styled.div`
  background-color: ${({ theme }) => theme.secondaryColor[800]};
   width: 100%; 
  padding: 3rem 1rem;
  color: ${({ theme }) => theme.secondary.contrastText};
  /* display: flex;
  flex-direction: row;
  a {
    color: ${({ theme }) => theme.secondary.contrastText};
    font-weight: 400;
  } */
`;

export const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
  p {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.grey[200]};
  }
  li {
    list-style: none;
    p {
      color: ${({ theme }) => theme.grey[200]};
      transition: 250ms color;
      cursor: pointer;
      font-size: 1rem;
      &:hover {
        color: ${({ theme }) => theme.white};
      }
    }
  }

  @media (max-width: ${deviceSizes.tablet}) {
    margin-top: 2rem;
  }
`;

export const Logo = styled(Image)`
  margin-bottom: ${({ bottom }) => bottom || '1rem'};
`;
export const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ColumnHeader = styled.div`
  line-height: 2rem;
  padding: 0 1rem 0 0;
  h5 {
    color: ${({ theme }) => theme.white};
  }
`;
export const SocialList = styled.ul`
  padding: 0.2rem;
  li {
    display: inline-block;
    margin-right: 0.9rem;
    & a {
      margin: 0;
    }
  }
  svg {
    fill: white;
  }
`;

export const ColumnContent = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: column;

  ul {
    padding: 0;
    margin: 0;
  }
  a {
    padding-right: 1rem;
    width: 100%;
    text-decoration: none;
  }
`;



export const useClasses = makeStyles({
  Search: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '25px',
      backgroundColor: '#616161',
      color: 'white',
      '&:hover fieldset': {
        borderColor: '#757575',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#757575',
      },
      '& svg': {
        fill: '#FFFFFF',
      },
    },
  },
  Quicklinks: {
    display: 'flex',
    justifyContent: 'center',
    [`@media(max-width: ${deviceSizes.sm})`]: {
      justifyContent: 'flex-start',
    },
  },
  BoxWithJMLogo: {
    display: 'flex',
    justifyContent: 'space-between',
    [`@media(max-width: ${deviceSizes.sm})`]: {
      flexDirection: 'column'
    },
  },
  MainContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    [`@media(max-width: ${deviceSizes.md})`]: {
      justifyContent: 'flex-start',
    },
  },
});
