import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@mui/material';
import Button from '@atoms/Button';
import Link from '@atoms/Link';
import SearchIcon from '@mui/icons-material/Search';

import { useTranslation } from '@app/config/i18n';
import { redirectToRoute } from '../../../../helpers'

import {
  Typography,
  HomepageContainer,
  InfoContainer,
  ActionContainer,

  ExploreButton
} from './style';


const HomeInfo = () => {

  const { t } = useTranslation('common', 'landing');
 


  
  const handleExploreOpportunities = () => {
    // opportunity_search
    redirectToRoute({}, 'opportunity_search');
  };

  return (
    <HomepageContainer>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <InfoContainer>
            <Typography variant="h1" component="h1" gutterBottom>
              Mubazar
            </Typography>
            <Typography variant="h4" component="h2" gutterBottom>
              {t('great_opp_musician')} <br /> {t('all_one_place')}
            </Typography>

            <ExploreButton
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={handleExploreOpportunities}
              size="large"
            >
              {t('explore_opportunities')}
            </ExploreButton>

            <ActionContainer>
              <Link href="register" variant="outlined" id="register" color="inherit">
                <Typography variant="subtitle1">{t('register_now')}</Typography>
              </Link>
              <Typography variant="subtitle1">/</Typography>
              <Link href="login" id="login" variant="outlined" color="inherit">
                <Typography variant="subtitle1">{t('login')}</Typography>
              </Link>
            </ActionContainer>
          </InfoContainer>
        </Grid>
      </Grid>
    </HomepageContainer>
  );
};

export default HomeInfo;
