import styled from 'styled-components';
import { Typography as Typo, Grid,} from '@mui/material';
import { deviceSizes } from '@app/config/devices';
import Button from '../../../atoms/Button';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px 50px;
`;

export const Typography = styled(Typo)`
  && {
    color: #fff;
  }
`;

export const HomepageContainer = styled(Grid)`
  padding-top: 2.25rem;
`;

export const ExploreBtn = styled(Button)`
  && {
    color: #fff;
    border-color: #fff;
    margin-right: 1rem;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  padding-top: 4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {    
    text-align: center;
  }
  
  @media (max-width: ${deviceSizes.sm}) {
    padding-top: 0;
    h2 {
      width: 75%;
    }
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 20%;
  align-items: center;
  color: white;
  h6 {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    &:hover:nth-child(1) {
      color: ${({ theme }) => theme.red[50]};
    }
    &:hover:nth-child(3) {
      color: ${({ theme }) => theme.red[50]};
    }
  }

  @media (max-width: ${deviceSizes.mobileL}) {
    width: 60%;
  }
`;




export const ExploreButton = styled(Button)`
  && {
    background-color: white;
    color: ${({ theme }) => theme.primary.main};
    
    margin: 20px 0;
    &:hover {
      background-color: ${({ theme }) => theme.secondaryColor[100]};
    }
  }
`;

