import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import { deviceSizes } from '@app/config/devices';

export const Header = styled.div`
  padding: 60px 150px 0 150px;
  position: relative;
  svg {
    margin: 10px;
  }
  @media (max-width: ${deviceSizes.md}) {
    padding: 20px;
  }
`;

export const useClasses = makeStyles({
  Table: {
    borderRadius: '16px !important',
    [`@media(max-width: ${deviceSizes.sm})`]: {
      marginLeft: '10px !important',
    },
  },
  Number8: {
    width: '24px',
    height: '24px',
    border: '2px solid #f35252',
    borderRadius: '50%',
    textAlign: 'center',
    margin: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  Funnel: {
    margin: '10px',
  },
  Indeterminate: {
    marginRight: '50px !important',
  },
  EllipseExport: {
    position: 'absolute',
    width: '200px',
    height: '200px',
    left: '350px',
    top: '-50px',
    [`@media(max-width: ${deviceSizes.md})`]: {
      left: '200px',
      top: '-130px',
    },
    [`@media(max-width: ${deviceSizes.sm})`]: {
      width: '100px',
      height: '100px',
      left: '230px',
      top: '-40px',
    },
  },
  FullNameAvatar: {
    marginRight: '10px',
  },
  ButtonsAndIcons: {
    [`@media(max-width: ${deviceSizes.md})`]: {
      display: 'none',
    },
  },
});
