import React from 'react';
import { Box, Grid } from '@mui/material';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import AccessAlarmRoundedIcon from '@mui/icons-material/AccessAlarmRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { StyledCard, StyledBox, StyledRightBox, SymphonyCurenccy } from './style';
import Typography from '../../../../atoms/Typography';
import { useTranslation } from '../../../../../config/i18n';

const SymphonyOrchestra = () => {
  const { t } = useTranslation('common', 'landing', 'howItWorks', 'applications', 'payment');

  return (
    <StyledCard>
      <Grid container spacing={3}>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <Box display="flex" flexDirection="column">
            <Typography variant="overline" gutterBottom>
              {t('howItWorks:symphony_orchestra.date')}
            </Typography>
            <Typography variant="h4" gutterBottom>
              {t('howItWorks:symphony_orchestra.title')}
            </Typography>
            <StyledBox>
              <LocationOnRoundedIcon style={{ color: '#616161' }} />
              <Typography variant="subtitle1" className="textColorGray" gutterBottom>
                {t('howItWorks:symphony_orchestra.address')}
              </Typography>
            </StyledBox>
            <StyledBox>
              <AccessAlarmRoundedIcon style={{ color: '#BB181E' }} />
              <Typography variant="body2" className="textColorDarkred" gutterBottom>
                {t('howItWorks:symphony_orchestra.deadline')}
              </Typography>
            </StyledBox>
            <StyledBox>
              <AssignmentRoundedIcon style={{ color: '#616161' }} />
              <Typography variant="caption" className="textColorGray" gutterBottom>
                6758 {t('applications:application_plural')}
              </Typography>
            </StyledBox>{' '}
            <StyledBox>
              <CheckCircleRoundedIcon color="primary" />
              <Typography variant="body2" className="textColorGray" gutterBottom>
                {t('howItWorks:symphony_orchestra.account')}: 123******089
              </Typography>
            </StyledBox>
          </Box>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <SymphonyCurenccy>
            <StyledRightBox>
              <Typography variant="button" gutterBottom>
                USD
              </Typography>
              <ArrowDropDownRoundedIcon />
            </StyledRightBox>
            <Typography variant="body2" gutterBottom>
              {t('howItWorks:symphony_orchestra.total_collected')}: <b>$7000,00</b>
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t('howItWorks:symphony_orchestra.available')}:
            </Typography>
            <Typography variant="h2" color="primary" gutterBottom>
              $6000,00
            </Typography>
            <Typography variant="button" className="requestPayout" gutterBottom>
              {t('payment:request.payout')}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t('howItWorks:symphony_orchestra.total_already_paid')}: <b>$0,00</b>
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t('howItWorks:symphony_orchestra.total_refunds_paid')}: <b> $800,00</b>
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t('howItWorks:symphony_orchestra.refund_requests')}:{' '}
              <b style={{ color: '#f35252', textDecoration: 'underline' }}> 4</b>
            </Typography>
          </SymphonyCurenccy>
        </Grid>
      </Grid>
    </StyledCard>
  );
};

export default SymphonyOrchestra;
