import styled from 'styled-components';
import { Box } from '@mui/material';
import {makeStyles} from '@mui/styles';
import { deviceSizes } from '@app/config/devices';
import Card from '../../../../atoms/Card';

export const Container = styled(Card)`
  padding: 50px;
  position: relative;
  overflow: visible !important;
  margin: 0 40px;
  @media (max-width: ${deviceSizes.md}) {
    margin: 0 40px;
  }
  @media (max-width: ${deviceSizes.sm}) {
    margin: 0 10px;
    padding: 0 10px;
  }
`;
export const HeaderBoxes = styled(Box)`
  display: flex;
  align-items: center;
  width: 20%;
  justify-content: space-evenly;
  @media (max-width: ${deviceSizes.md}) {
    justify-content: flex-start;
    margin-top: 20px;
    width: 100%;
  }
`;

export const AverageRating = styled(Card)`
  width: 304px;
  padding: 15px;
  position: absolute;
  z-index: 10;
  top: -50px;
  right: -20px;
  @media (max-width: ${deviceSizes.sm}) {
    display: none;
  }
`;

export const Header = styled(Box)`
  display: flex;
  alignitems: center;
  justify-content: space-between;
  margin-top: 20px;
  @media (max-width: ${deviceSizes.md}) {
    flex-direction: column;
  }
`;
export const useClasses = makeStyles({
  Number8: {
    color: '#f35252',
    border: '1px solid #f35252',
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ImageWithIcons: {
    [`@media (max-width: ${deviceSizes.sm})`]: {
      display: 'none',
    },
  },
});
