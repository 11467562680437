import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton } from './style';

const RoundedButton = ({ variant, color, children, ...props }) => {
  return (
    <StyledButton variant={variant === 'text' ? undefined : variant} color={color} {...props}>
      {children}
    </StyledButton>
  );
};

RoundedButton.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  color: PropTypes.string,
};

RoundedButton.defaultProps = {
  children: undefined,
  variant: 'contained',
  // block: false,
  color: undefined,
};

export default RoundedButton;