import { gql } from '@apollo/client';
import Fragments from 'apollo/fragments';

export const UPDATE_FILTER_QUERY = gql`
  mutation updateFilterQuery($query: String!) {
    updateFilterQuery(query: $query) @client
  }
`;

export const UPDATE_FILTER_SORT = gql`
  mutation updateFilterSort($field: String!, $asc: Boolean) {
    updateFilterSort(field: $field, asc: $asc) @client
  }
`;

export const UPDATE_FILTER_PAGING = gql`
  mutation updateFilterPaging($page: Int!, $first: Int!) {
    updateFilterPaging(page: $page, first: $first) @client
  }
`;

export const CREATE_REVIEW = gql`
  mutation createReview($input: ReviewInput!) {
    createReview(input: $input) {
      id
      # user
      rate
      # application
      created_at
    }
  }
`;

// export const UPDATE_REVIEW = gql`
//   mutation updateReview($id: ID!, $input: ReviewInput!) {
//     updateReview(input: $input, id: $id) {
//       id
//       # user
//       rate
//       # application
//       created_at
//     }
//   }
// `;

export const CREATE_COMMENT = gql`
  mutation createComment($input: CommentInput!) {
    createComment(input: $input) {
      id
      content
      user {
        ...UserInformation
      }
      created_at
      # replies_count
      # is_reply
    }
  }
  ${Fragments.userInfo}
`;

export const UPDATE_COMMENT = gql`
  mutation updateComment($id: ID!, $input: UpdateCommentInput!) {
    updateComment(id: $id, input: $input) {
      id
      content
      user {
        ...UserInformation
      }
      created_at
      # replies_count
      # is_reply
    }
  }
  ${Fragments.userInfo}
`;

export const DELETE_COMMENT = gql`
  mutation deleteComment($id: ID!) {
    deleteComment(id: $id) {
      id
    }
  }
`;

export const CREATE_MESSAGE = gql`
  mutation createApplicationMessage($input: NewApplicationMessageInput!) {
    createApplicationMessage(input: $input) {
      message
      user_id
      created_at
    }
  }
`;

export const DELETE_MESSAGE = gql`
  mutation deleteApplicationMessage($id: ID!) {
    deleteApplicationMessage(id: $id) {
      message
      
    }
  }
`;

export const UPDATE_APPLICATION = gql`
  mutation updateApplication($id: ID!, $status: ApplicationStatus!) {
    updateApplication(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const DELETE_APPLICATION = gql`
  mutation deleteApplication($id: ID!) {
    deleteApplication(id: $id) {
      id
    }
  }
`;

export const DELETE_APPLICATIONS = gql`
  mutation deleteApplications($id: [ID!]!) {
    deleteApplications(id: $id) {
      id
    }
  }
`;

export const INVITE_REVIEWER = gql`
  mutation inviteReviewer($input: InvitationInput) {
    inviteReviewer(input: $input) {
      status
      message
    }
  }
`;

export const CREATE_PAYMENT_INTENT = gql`
  mutation createPaymentIntent($application: String!, $code: String!, $coupon: String) {
    createPaymentIntent(application: $application, code: $code, coupon: $coupon)
  }
`;

export const CONFIRM_PAYMENT = gql`
  mutation confirmPayment($id: String!) {
    confirmPayment(id: $id) {
      id
      status
      payment_status
    }
  }
`;

export const CONFIRM_FREE_COUPON = gql`
  mutation confirmFreeCoupon($id: String!,$code: String! $coupon: String) {
    confirmFreeCoupon(id: $id,code: $code,  coupon: $coupon) {
      id
      status
      # code
    }
  }
`;

export const CONFIRM_EXTERNAL_PAYMENT = gql`
  mutation confirmExternalPayment($input: ExternalPaymentInput!) {
    confirmExternalPayment(input: $input) {
      id
      status
    }
  }
`;

export const SEND_PAYMENT_REMINDER = gql`
  mutation sendApplicationReminder($id: ID!) {
    sendApplicationReminder(id: $id) {
      payment_status
    }
  }
`;
