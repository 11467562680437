/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
// import PropTypes from 'prop-types';
import { Twitter, Facebook, Instagram, YouTube } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, IconButton, InputAdornment, Container, Typography, Box, Tooltip, } from '@mui/material';
import { setLocalStorage } from '@app/helpers/hooks';
import { redirectToRoute } from '@app/helpers';
import {
  FooterContainer,
  FooterColumn,
  SocialList,
  Logo,
  ColumnHeader,
  ColumnContent,
  useClasses,
} from './style';
import Link from '../../../atoms/Link';
import SubFooter from '../SubFooter';
import { useTranslation } from '../../../../config/i18n';
import TextField from '../../../atoms/TextField'
import Button from '../../../atoms/Button'


const FooterLogo = '/images/mz_logo.png';
const belsop = '/images/belspo.png';
const creativeEropean = '/images/europien_new1.png';
const musicaire = '/images/Musicaire.png';
const musicMoveEurope = '/images/Music-Moves.png';
const JMILogo = '/images/jmi-logo.png';



const Footer = () => {


  const { t } = useTranslation(['footer', 'warnings', 'explore', 'dashboard']);

  const classes = useClasses()


  const handleSearchOpportunity = (e) => {

    if (e.key === "Enter") {
      setLocalStorage('filterKeyWord', e.target.value);
      redirectToRoute({}, 'opportunity_search');
    }
  }

  const handleStaticRouteRedirect = (route) => {
    redirectToRoute({}, route);
  }

  return (
    <>
      <FooterContainer>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <FooterColumn>
                <ColumnHeader>
                  <Logo bottom="0.3125rem" width="12.5rem" src={FooterLogo} />
                </ColumnHeader>
                <Typography variant="body2">{t('about_details')}</Typography>
                <TextField
                  defaultValue=""
                  // value="aaa"
                  name="FooterSearch"
                  placeholder={t('explore:search')}
                  onKeyPress={(e) => handleSearchOpportunity(e)}
                  className={classes.Search}
                  variant="outlined"
                  // onKeyDown={handleKeyDownTitle}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                // InputLabelProps={{ shrink: Boolean(values.title) }}
                />
                <SocialList>
                  <li>
                    <a
                      title="facebook"
                      rel="noopener"
                      target="_blank"
                      href="https://www.facebook.com/mubazar"
                    >
                      <Facebook />
                    </a>
                  </li>
                  <li>
                    <a
                      title="instagram"
                      rel="noopener"
                      target="_blank"
                      href="https://www.instagram.com/mubazar"
                    >
                      <Instagram />
                    </a>
                  </li>
                  <li>
                    <a
                      title="twitter"
                      rel="noopener"
                      target="_blank"
                      href="https://www.twitter.com/mubazar"
                    >
                      <Twitter />
                    </a>
                  </li>
                  <li>
                    <a
                      title="youtube"
                      rel="noopener"
                      target="_blank"
                      href="https://www.youtube.com/mubazar"
                    >
                      <YouTube />
                    </a>
                  </li>
                </SocialList>
              </FooterColumn>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12} className={classes.Quicklinks}>
              <FooterColumn>
                <ColumnHeader>
                  <Typography variant="subtitle1">{t('quick links')}</Typography>
                </ColumnHeader>
                <ColumnContent>
                  <ul>
                    <li>
                      <Typography
                        variant="body1"
                        onClick={() => handleStaticRouteRedirect('about_us')}
                      >
                        {t('about')}
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1" onClick={() => handleStaticRouteRedirect('help')}>
                        {t('help')}
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body1"
                        onClick={() => handleStaticRouteRedirect('terms')}
                      >
                        {t('terms')}
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body1"
                        onClick={() => handleStaticRouteRedirect('privacy')}
                      >
                        {t('privacy')}
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body1"
                        onClick={() => handleStaticRouteRedirect('contact')}
                      >
                        {t('contact')}
                      </Typography>

                    </li>
                    {/* <li>
                    <a href="https://mubazar.com/">{t('warnings:legacy')}</a>
                  </li> */}
                  </ul>
                </ColumnContent>
              </FooterColumn>
            </Grid>
            <Grid item lg={5} md={6} sm={9} xs={12}>
              <Box display="flex" flexDirection="column">
                <Box className={classes.BoxWithJMLogo}>
                  <FooterColumn>
                    <ColumnHeader>
                      <Typography variant="subtitle1">{t('powered')}</Typography>
                    </ColumnHeader>
                    <ColumnContent>
                      <a href="http://jmi.net" target="_blank" rel="noopener noreferrer">
                        <Logo bottom="0.3125rem" width="14rem" src={JMILogo} />
                      </a>
                    </ColumnContent>
                  </FooterColumn>
                  <FooterColumn>
                    <ColumnHeader>
                      <Typography variant="subtitle1">{t('supported')}</Typography>
                    </ColumnHeader>
                    <ColumnContent>
                      <a
                        href="https://www.belspo.be/belspo/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Logo bottom="0.3125rem" width="3.125rem" src={belsop} />
                      </a>
                      <a
                        href="https://ec.europa.eu/programmes/creative-europe/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Logo bottom="0.3125rem" width="12.5rem" src={creativeEropean} />
                      </a>
                      
                        <Logo bottom="1rem" fitCover={false} width="6rem" height="5rem" src={musicMoveEurope} />
                        <Logo bottom="1rem" width="6rem" height="5rem" src={musicaire} />
                    </ColumnContent>
                    
                  </FooterColumn>
                </Box>{' '}
                <Button
                  variant="contained"
                  color="primary"
                  asLink
                  href="mailto:support@mubazar.com"
                  sx={{ width: '100%', zIndex: 1 }}
                >
                  {t('contact_suport')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </FooterContainer>
      <SubFooter />
    </>
  );
};

Footer.propTypes = {};

export default Footer;

// eslint-disable-next-line no-lone-blocks
{/* <a target="_blank" rel="noopener noreferrer" href="https://suport@mubazar.com">
    {' '}
    Contact support
</a>; */}
