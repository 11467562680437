import styled from 'styled-components';
import { Box } from '@mui/material';
import Card from '../../../../atoms/Card';
import {deviceSizes} from '../../../../../config/devices'

export const StyledCard = styled(Card)`
  
  padding: 50px;
  z-index: 12;
  position: relative;
  margin: 0px 70px;
  @media (max-width: ${deviceSizes.md}) {
    
    margin: 0 40px;
    position:static;
  }
`;

export const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .textColorGray {
    color: ${({ theme }) => theme.secondaryColor[600]};
  }
  .textColorDarkred {
    color: ${({ theme }) => theme.red[900]};
  }
  p, h6 {
    margin-bottom: 0;
  }

  svg {
    margin-right: 10px;
  }
`;
export const SymphonyCurenccy = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  p {
    color: ${({ theme }) => theme.secondaryColor[600]};
  }
  .requestPayout {
    color: ${({ theme }) => theme.red[400]};
  }
  @media (max-width: ${deviceSizes.md}) {
    justify-content: flex-start;
  }
`;

export const StyledRightBox = styled(Box)`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  color: ${({theme}) => theme.secondaryColor[700]};
  svg {
    margin-left: 10px;
    fill: ${({theme}) => theme.secondaryColor[700]};
  }
`;