/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react';
import { Box } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import SortIcon from '@mui/icons-material/Sort';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import DeleteIcon from '@mui/icons-material/Delete';
import MailIcon from '@mui/icons-material/Mail';
import ListIcon from '@mui/icons-material/List';
import AddIcon from '@mui/icons-material/Add';

import MaterialTable from 'material-table';

import { Header, useClasses } from './style';
import Button from '../../../../atoms/Button';


import { colors } from '../../../../../config/colors';
import { useTranslation } from '../../../../../config/i18n';
import Image from '../../../../atoms/Image';
import Typography from '../../../../atoms/Typography';

const ExportEverything = () => {
const { t } = useTranslation(
  'common',
  'landing',
  'howItWorks',
  'wizard',
  'applications',
  'payment'
);
const classes = useClasses();

const [hidenColumns, setHidenColumns] = useState(false);

useEffect(() => {
  if (window.innerWidth < 960) {
    setHidenColumns(true);
  }
}, []);


    const columns = [
      {
        title: `${t('howItWorks:aplicant')}`,
        sorting: false,
        field: 'full_name',
        render: (rowData) => (
          <Box display="flex" className={classes.full_name}>
            <Image
              width="40px"
              height="40px"
              src={rowData.avatar}
              alt={rowData.full_name}
              className={classes.FullNameAvatar}
            />
            <Typography variant="overline">{rowData.full_name}</Typography>
          </Box>
        ),
      },

      { title: `${t('wizard:date')}`, field: 'date', align: 'right', hidden: hidenColumns },
      {
        title: `${t('applications:rating')}`,
        field: 'rating',
        hidden: hidenColumns,
      },
      {
        title: `${t('common:status')}`,
        field: 'status',
        hidden: hidenColumns,
        render: (rowData) => (
          <div
            style={{
              color:
                rowData.status === 'Aproved'
                  ? '#67BF4E'
                  : rowData.status === 'Denied'
                  ? '#BB181E'
                  : '',
            }}
          >
            {rowData.status}
          </div>
        ),
      },
      {
        title: `${t('payment:payment')}`,
        field: 'payment',
        render: (rowData) => (
          <div
            style={{
              color:
                rowData.payment === 'Paid'
                  ? '#67BF4E'
                  : rowData.payment === 'Unpaid'
                  ? '#BB181E'
                  : '',
            }}
          >
            {rowData.payment}
          </div>
        ),
      },
      {
        title: `${t('common:addres')}`,
        field: 'addres',
        hidden: hidenColumns,
      },
      {
        title: `${t('common:email')}`,
        field: 'email',
      },
      {
        title: `${t('common:artist_name')}`,
        field: 'artistname',
        hidden: hidenColumns,
      },
    ];
    const data = [
      {
        id: 1,
        avatar: '/images/home/TsepoMohapi.png',
        full_name: 'Tsepo Mohapi',
        date: '17/09/20',
        rating: 3,
        status: `${t('common:approved')}`,
        payment: `${t('payment:paid')}`,
        addres: '82 Balcairn, 238 Main Road, Bryanston, Johannesburg, South Africa',
        email: 'tsepo.m@yahoo.com',
        tableData: { checked: true },
      },
      {
        id: 2,
        avatar: '/images/home/avatar.png',
        full_name: 'Hubert Blaine Wolfeschlegelstein',
        date: '12/09/20',
        rating: 3,
        status: `${t('howItWorks:denied')}`,
        payment: `${t('payment:status.requires_creation')}`,
        addres: 'Hauptstrasse 4a, 45678 Hamburg, Germany',
        email: 'hubert.blaine.wolfie567@gmail.com',
      },
    ]; 


    return (
      <MaterialTable
        columns={columns}
        title={`${t('applications:application')}(123)`}
        // padding="checkbox"
        className={classes.Table}
        data={data}
        components={{
          Toolbar: (props) => (
            <Header>
              <Image
                src="/images/home/EllipseExport.png"
                alt="Expot button"
                className={classes.EllipseExport}
              />
              <Box display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <Typography variant="h5">{t('applications:application')}</Typography>
                  <Typography variant="caption">(123)</Typography>
                </Box>
                <Box display="flex" alignItems="center" className={classes.ButtonsAndIcons}>
                  <SettingsIcon color="primary" />
                  <SortIcon color="primary" />
                  <Box className={classes.Number8}>
                    <Typography variant="caption" color="primary">
                      8
                    </Typography>
                  </Box>

                  <Image
                    src="/images/home/Funnel.png"
                    alt="Funnel"
                    width="24px"
                    height="24px"
                    className={classes.Funnel}
                  />
                  <Typography variant="caption">79 {t('howItWorks:of')} 123</Typography>
                  <SearchIcon color="primary" />
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <IndeterminateCheckBoxIcon color="secondary" className={classes.Indeterminate} />
                  <DeleteIcon color="primary" />
                  <MailIcon color="primary" />
                  <Image
                    src="/images/home/ExportWithCursor.png"
                    alt="Export Cursor"
                    width="45px"
                    height="45px"
                  />
                </Box>
                <Box display="flex" alignItems="center" className={classes.ButtonsAndIcons}>
                  <Button variant="text" color="primary" startIcon={<ListIcon />}>
                    {t('howItWorks:view_summary')}
                  </Button>
                  <Button variant="text" color="primary" startIcon={<AddIcon />}>
                    {t('applications:add_applicant')}
                  </Button>
                </Box>
              </Box>
            </Header>
          ),
        }}
        options={{
          selection: true,
          search: false,
          //   searchIcon: true,
          exportButton: true,

          showSelectAllCheckbox: false,
          paging: false,
          cellStyle: {
            whiteSpace: 'nowrap',
          },
          headerStyle: {
            color: `${colors.secondaryColor[600]}`,
            textTransform: 'uppercase',
            fontWeight: 600,
          },
          rowStyle: (rowData) => ({
            backgroundColor: rowData.tableData.checked ? `${colors.secondaryColor[100]}` : '',
          }),
        }}
      />
    );
}

export default ExportEverything;