import styled from 'styled-components';
import { deviceSizes } from '@app/config/devices';

export const Container = styled.div`
  padding: 20px;
  display: flex;
  position: absolute;
  right: 0px;
  top: -160px;
  background-color: white;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 14%), 0px 2px 2px rgb(0 0 0 / 12%),
    0px 1px 3px rgb(0 0 0 / 20%);

  svg {
    fill: #67bf4e;
    width: 50px;
    height: 50px;
    margin: 0 20px;
  }
  @media(max-width: ${deviceSizes.lg}){
    display: none
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  .messageSent {
    color: #67bf4e;
  }
`;