import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Container, Typography, MenuItem, Box } from '@mui/material';

import LocaleSelect from '../../../molecules/LocaleSelect';
import { useTranslation } from '../../../../config/i18n';
import { currencies } from '../../../../constants';
import { SubFooterContainer, StyledTextField, CurrencyBox, LanguagesBox } from './style';

export default function SubFooter() {
  const { t } = useTranslation(['footer']);
  const [currencyValue, setCurrencyValue] = useState('eur');

  // const currenciesList = useMemo(
  //   () => currencies.map((currency) => ({ label: currency.toUpperCase(), value: currency })),
  //   currencies
  // );

  return (
    <SubFooterContainer>
      <Container maxWidth="xl">
        <Grid container className="mainContainer">
          <Grid item lg={6} sm={6} md={6} xs={12} className="copyright">
            <Typography variant="body2">
              © {new Date().getFullYear()} {t('copyright')}
            </Typography>
          </Grid>
          <Grid item lg={3} sm={6} md={4} xs={12} width="100%">
            <Grid container alignItems="center" justifyContent="flex-end" className='currency'>
              <CurrencyBox item lg={5} sm={4} xs={3}>
                <StyledTextField
                  id="standard-select-currency"
                  select
                  // label={t('payment:currency')}
                  value={currencyValue}
                  // disabled
                  onChange={(e) => setCurrencyValue(e.target.value)}
                  // variant="outlined"

                  // disabled={fee.is_used}
                  //   fullWidth={fullWidth}
                >
                  {currencies.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Typography variant="caption">{option.toLocaleUpperCase()}</Typography>
                    </MenuItem>
                  ))}
                </StyledTextField>
              </CurrencyBox>
              <LanguagesBox item lg={5} md={6} xs={3}>
                <LocaleSelect id="footer-language-menu" className="locales" />
              </LanguagesBox>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </SubFooterContainer>
  );
}
