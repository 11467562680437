import React, { useState, useEffect} from 'react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import { Container, StyledCard,StyledHeder, StaticMenu,ReviewersWithCursor, MenuBox } from './style';
import Typography from '../../../../atoms/Typography';
import { useTranslation } from '../../../../../config/i18n';
import Image from '../../../../atoms/Image';
import Button from '../../../../atoms/Button';

const Reviewers = () => {
  const { t } = useTranslation('common', 'landing', 'dahsboard', 'howItWorks');
  const [items, setItems] = useState([
    { name: 'Matt Clark', path: '/images/home/MattClark.png' },
    { name: 'Jeremy Jones', path: '/images/home/JeremyJones.png' },
    { name: 'Jan-Hendrik Terblanche', path: '/images/home/JanHendrix.png' },
  ]);

  useEffect(() => {
    if(window.innerWidth < 1080){
      setItems([{ name: 'Matt Clark', path: '/images/home/MattClark.png' }]);
    }
  }, [])
  
  const menuItems = [
    { name: 'Mike Maloney', path: '/images/home/MikeMaloney.png' },
    { name: 'Sasha Zwerev', path: '/images/home/SashaZwerev.png' },
    { name: 'Tippy Wiliams', path: '/images/home/TippiWiliams.png' },
    { name: 'Danni Minoque', path: '/images/home/DanniMinoque.png' },
  ];

  return (
    <Container>
      <StyledCard>
        <StyledHeder>
          <Box display="flex" alignItems="center">
            <Typography variant="h5">{t('applications:other_reviewer_plural')}</Typography>
            <Typography variant="caption">(10)</Typography>
          </Box>
          <Button variant="text" color="primary" startIcon={<AddIcon />}>
            {t('howItWorks:add_reviewers')}
          </Button>
        </StyledHeder>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            {items.map((item, index) => (
              <Box display="flex" key={item.name}>
                <Image width="20px" height="20px" src={item.path} className="smallimages" />
                <Typography variant="subtitle1">{item.name}</Typography>
                <CloseIcon fontSize="small" color="primary" />
              </Box>
            ))}
          </Box>
          <StaticMenu>
            <ReviewersWithCursor>
              <Typography variant="caption" color="primary">
                4 {t('applications:other_reviewers_plural')}
              </Typography>
              <Image src="/images/home/cursor.png" width="18px" height="23px" className="Cursor" />
            </ReviewersWithCursor>
            <MenuBox>
              {menuItems.map((item, index) => (
                <Box display="flex" key={item.name}>
                  <Image width="20px" height="20px" src={item.path} className="smallimages" />
                  <Typography variant="body2" gutterBottom>
                    {item.name}
                  </Typography>
                </Box>
              ))}
            </MenuBox>
          </StaticMenu>
        </Box>
      </StyledCard>
    </Container>
  );
};

export default Reviewers;
