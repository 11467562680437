import React from 'react';
import ArchiveIcon from '@mui/icons-material/Archive';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ReplyIcon from '@mui/icons-material/Reply';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import { Box } from '@mui/material';
import { StyledCard, Header, Title, Item, ItemContent, Rows, useClasses } from './style';
import { useTranslation } from '../../../../../config/i18n';
import { colors } from '../../../../../config/colors';
import Typography from '../../../../atoms/Typography';
import RoundedButton from '../../../../atoms/RoundedButton';
import Spacer from '../../../../atoms/Spacer';
import Image from '../../../../atoms/Image';

const HowItWorksMessages = () => {
  const { t } = useTranslation('common', 'landing', 'howItWorks');
  const classes = useClasses();

  const messages = [
    {
      avatar: '/images/home/TsepoMohapi.png',
      title: 'Tsepo Mohapi',
      description: `${t('howItWorks:messages_columns.description1')}`,
      time: '8:26AM',
    },
    {
      avatar: '',
      title: 'Hubert Blaine Wolfe...',
      description: `${t('howItWorks:messages_columns.description2')}`,
      time: '5/12/20',
    },
    {
      avatar: '/images/home/Lerato.png',
      title: 'Lerato Buthelezi',
      description: `${t('howItWorks:messages_columns.description3')}`,
      time: '7/11/20',
    },
    {
      avatar: '/images/home/Imen.png',
      title: 'Imen Ben Haj Yahia',
      description: `${t('howItWorks:messages_columns.description4')}`,
      time: '7/10/20',
    },
  ];

  return (
    <StyledCard>
      <Header>
        <Typography variant="h5">
          {t('howItWorks:messages')} <Typography variant="caption">(123)</Typography>
        </Typography>
        <RoundedButton color="primary">{t('howItWorks:compose_message')}</RoundedButton>
      </Header>
      <Spacer width="100%" height="1rem" />
      <Title>
        <Typography className={classes.All} variant="overline">
          {t('howItWorks:all')}
        </Typography>
        <Typography variant="overline">{t('howItWorks:favourites')}</Typography>
        <Typography variant="overline">{t('howItWorks:archive')}</Typography>
      </Title>
      {messages.map((item, index) => (
        <Item key={item.title}>
          {index === 1 ? (
            <AccountCircleIcon fontSize="large" color="primary" />
          ) : (
            <Image width="40px" height="40px" src={item.avatar} />
          )}

          <ItemContent>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1" gutterBottom>
                {item.title}
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                {item.time}
              </Typography>
            </Box>

            <Typography variant="body2" gutterBottom>
              {item.description}
            </Typography>
          </ItemContent>
          <ArchiveIcon fontSize="small" color="secondary" />
          {index === 0 || index === 1 ? (
            <StarIcon className={classes.GoldStar} fontSize="small" />
          ) : (
            <StarBorderIcon className={classes.Star} fontSize="small" color="secondary" />
          )}

          <ReplyIcon fontSize="small" color="secondary" />
        </Item>
      ))}
      <Rows>
        <Box display="flex" alignItems="center" mr={4}>
          {' '}
          <Typography variant="body2">10 {t('howItWorks:rows')}</Typography>
          <ArrowDropDownRoundedIcon fontSize="small" color="secondary" />
        </Box>
        <Box display="flex" alignItems="center">
          <FirstPageRoundedIcon fontSize="small" color="secondary" />{' '}
          <KeyboardArrowLeftRoundedIcon fontSize="small" color="secondary" />{' '}
          <Typography
            variant="caption"
            color="secondary"
            className={classes.Sites}
          >
            1-5 {t('howItWorks:of')} 5
          </Typography>{' '}
          <KeyboardArrowRightRoundedIcon fontSize="small" color="secondary" />
          <LastPageRoundedIcon fontSize="small" color="secondary" />
        </Box>
      </Rows>
    </StyledCard>
  );
};

HowItWorksMessages.getInitialProps = async () => ({
  namespacesRequired: ['howItWorks'],
});

export default HowItWorksMessages;
