import styled from 'styled-components';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { deviceSizes } from '@app/config/devices';
import Card from '../../../../atoms/Card';
import Typography from '../../../../atoms/Typography';


export const Container = styled.div`
  position: relative;
  margin-left: 40px;
  @media (max-width: ${deviceSizes.sm}) {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
`;

export const StyledCard = styled(Card)`
  width: 305px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  & .CardAvatar {
    position: absolute;
    z-index: 1;
    top: 165px;
    right: 10px;
  }
  @media(max-width: ${deviceSizes.tablet}){
    width: 250px;
  }
`;

export const Cardheaer = styled.div`
  height: 200px;
  border-bottom: 4px solid ${({ theme }) => theme.primary.main};
`;

export const CardContent = styled.div`
  padding: 20px;
`;
export const StyledBox = styled(Box)`
  && {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
      fill: ${({ theme }) => theme.secondaryColor[150]};
    }
  }
`;

export const StyledTypography = styled(Typography)`
  && {
    width: fit-content;
    padding: 5px 10px;
    background-color: ${({ theme }) => theme.secondaryColor[50]};
    margin: 0 auto;
    margin-top: 25px;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.secondaryColor[400]};
  }
`;

export const useClasses = makeStyles({
  Accepted: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    filter: 'drop-shadow(4px 8px 16px rgba(0, 0, 0, 0.25))',
    width: 'fit-content',
    position: 'absolute',
    bottom: '20px',
    left: '-60px',
    textTransform: 'capitalize',
    fontWeight: 'normal',
    zIndex: 2,
    '&:hover': {
      backgroundColor: 'white',
    },
    [`@media(max-width: ${deviceSizes.sm})`]: {
      left: '60px',
    },
  },
  Ellipse: {
    position: 'absolute',
    bottom: '-45px',
    left: '-50px',
    [`@media(max-width: ${deviceSizes.sm})`]: {
      left: '15px',
    },
  },
});
