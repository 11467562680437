import styled from 'styled-components';
import FCLabel from '@mui/material/FormControlLabel';

export const FormControlLabel = styled(FCLabel)`
  && {
    .MuiFormControlLabel-label {
      margin-left: 1.6rem;
    }
  }
`;
