import styled from 'styled-components';
import { Container as MContainer } from '@mui/material';
import { deviceSizes } from '@app/config/devices';

// export const BoxContianer = styled(MContainer)`
//   padding-top: 80px;
//   padding-bottom: 9.375rem;
//   min-height: 80vh;
//   width: 100%;
// `;

export const LandingContainer = styled.div`
  background-color: ${({ theme }) => theme.primary.main};
  /* height: 100vh; */
  min-height: 50.875rem;
  padding-top: 80px;

  @media (max-width: ${deviceSizes.tablet}) {
    min-height: 42.875rem;
  }
  @media (max-width: ${deviceSizes.sm}) {
    min-height: 37.875rem;
  }
  /* 
background: #f35252;
background: -webkit-linear-gradient(to bottom, #880E4F, #f35252);  
background: linear-gradient(to bottom, #f35252, #880E4F); 
 */
`;

// CB356B

// f35252
