/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Box, Divider } from '@mui/material';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import AddIcon from '@mui/icons-material/Add';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import SearchIcon from '@mui/icons-material/Search';
import MaterialTable from 'material-table';
import { Rating } from '@mui/lab';
import { Container, Header, HeaderBoxes, AverageRating, useClasses } from './style';
import Typography from '../../../../atoms/Typography';
import Image from '../../../../atoms/Image';
import { useTranslation } from '../../../../../config/i18n';
import Button from '../../../../atoms/Button';
import { colors } from '../../../../../config/colors';

const Applications = () => {
  const { t } = useTranslation(
    'common',
    'landing',
    'applications',
    'howItWorks',
    'payment',
    'wizard'
  );
  const classes = useClasses();
  const [hidenColumns, setHidenColumns] = useState(false)


  useEffect(() => {
    if(window.innerWidth < 960 ) {
      setHidenColumns(true)
    }
  }, [])

  const columns = [
    {
      sorting: false,
      field: 'avatar',
      render: (rowData) => <Image width="40px" height="40px" src={rowData.avatar} />,
    },
    { title: `${t('howItWorks:aplicant')}`, field: 'full_name' },
    { title: `${t('wizard:date')}`, field: 'date', align: 'right', hidden: hidenColumns },
    {
      title: `${t('applications:rating')}`,
      field: 'rating',
      render: (rowData) => (
        <Rating
          icon={<StarRoundedIcon />}
          emptyIcon={<StarOutlineRoundedIcon />}
          name="hover-feedback"
          value={rowData.rating}
          readOnly
        />
      ),
    },
    {
      title: `${t('common:status')}`,
      field: 'status',
      hidden: hidenColumns,
      render: (rowData) => (
        <div
          style={{
            color:
              rowData.status === 'Aproved'
                ? '#67BF4E'
                : rowData.status === 'Denied'
                ? '#BB181E'
                : '',
          }}
        >
          {rowData.status}
        </div>
      ),
    },
    {
      title: `${t('payment:payment')}`,
      field: 'payment',
      hidden: hidenColumns,
      render: (rowData) => (
        <div
          style={{
            color:
              rowData.payment === 'Paid'
                ? '#67BF4E'
                : rowData.payment === 'Unpaid'
                ? '#BB181E'
                : '',
          }}
        >
          {rowData.payment}
        </div>
      ),
    },
  ];

  const data = [
    {
      id: 1,
      avatar: '/images/home/TsepoMohapi.png',
      full_name: 'Tsepo Mohapi',
      date: '17/09/20',
      rating: 3,
      status: `${t('common:approved')}`,
      payment: `${t('payment:paid')}`,
      tableData: { checked: true },
    },
    {
      id: 2,
      avatar: '/images/home/avatar.png',
      full_name: 'Hubert Blaine Wolfeschlegelstein',
      date: '12/09/20',
      rating: 3,
      status: `${t('howItWorks:denied')}`,
      payment: `${t('payment:status.requires_creation')}`,
    },
    {
      id: 3,
      avatar: '/images/home/Lerato.png',
      full_name: 'Lerato Buthelezi',
      date: '7/09/20',
      rating: 3,
      status: `${t('applications:pending')}`,
      payment: `${t('payment:status.refund_requested')}`,
    },
    {
      id: 4,
      avatar: '/images/home/Imen.png',
      full_name: 'Imen Ben Haj Yahia',
      date: '17/09/20',
      rating: 3,
      status: `${t('applications:pending')}`,
      payment: `${t('payment:status.refund_requested')}`,
    },
  ]; 
  

  return (
    <Container>
      <AverageRating>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="overline">{t('applications:avg_rating')}</Typography>
            <InfoOutlinedIcon fontSize="small" color="primary" />
          </Box>
          <Box display="flex" alignItems="center">
            <Typography variant="h5" style={{ color: '#FAB32B' }}>
              5
            </Typography>
            <StarRoundedIcon style={{ color: '#FAB32B' }} />
          </Box>
        </Box>
        <Typography variant="caption">{t('howItWorks:rate_this')}</Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="caption">{t('applications:application_plural')}: </Typography>
          <Rating
            icon={<StarRoundedIcon />}
            emptyIcon={<StarOutlineRoundedIcon />}
            name="hover-feedback"
            value={0}
            readOnly
          />
        </Box>
      </AverageRating>
      <Header>
        <HeaderBoxes>
          <Typography variant="h5">{t('applications:application_plural')}</Typography>
          <Typography variant="caption">(123)</Typography>
        </HeaderBoxes>
        <HeaderBoxes>
          <Box className={classes.Number8}>
            <Typography variant="caption">8</Typography>
          </Box>
          <Image src="/images/home/Funnel.png" width="24px" height="24px" />
          <Typography variant="body2"> 29 {t('howItWorks:of')} 123</Typography>
          <SearchIcon color="primary" />
        </HeaderBoxes>
      </Header>
      <Header>
        <Box display="flex" alignItems="center" className={classes.ImageWithIcons}>
          <Image width="192px" height="24px" src="/images/home/GroupIcons.png" />
        </Box>

        <Box>
          <Button startIcon={<KeyboardIcon />} color="primary" variant="text">
            {t('howItWorks:view_as_spreadsheet')}
          </Button>
          <Button startIcon={<AddIcon />} color="primary" variant="text">
            {t('applications:add_applicant')}
          </Button>
        </Box>
      </Header>
      <Divider />
      <MaterialTable
        // tableRef={tableRef}
        // title="Igor"
        style={{ boxShadow: 'none' }}
        columns={columns}
        // padding="checkbox"
        data={data}
        options={{
          selection: true,
          toolbar: false,
          showSelectAllCheckbox: false,
          paging: false,
          headerStyle: {
            color: `${colors.secondaryColor[600]}`,
            textTransform: 'uppercase',
            fontWeight: 600,
          },
          rowStyle: (rowData) => ({
            backgroundColor: rowData.tableData.checked ? `${colors.secondaryColor[100]}` : '',
          }),
        }}
        icons={{
          SortArrow: () => <ArrowDropDownRoundedIcon />,
          Check: () => <CheckBoxIcon style={{ fill: 'red' }} />,
        }}
      />
    </Container>
  );
};

export default Applications;
