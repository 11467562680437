import React from 'react'

import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '../../../../atoms/Typography'
import {
  StyledCard,
  SearchBox,
  StyledItem,
  StyledTypographyBox,
  useClasses,
  FiltersContainer,
} from './style';
import { useTranslation } from '../../../../../config/i18n';
import Image from '../../../../atoms/Image'

const HowItWorksFilters = () => {
const { t } = useTranslation('common', 'landing', 'wizard', 'explore', 'payment', 'howItWorks');
const classes = useClasses()
    const items = [
      t('howItWorks:how_it_works_card.type_of_opportunity'),
      t('wizard:opp_location'),
      t('common:nationalities'),
      t('common:instruments'),
      t('common:genres'),
      t('explore:skill_level'),
      `${t('common:individual')}/${t('common:band')}`,
      t('common:age_range'),
      t('common:deadline'),
      t('payment:payment'),
    ];

    return (
      <FiltersContainer>
        <Image
          src="/images/home/Ellipse 33.png"
          width="124px"
          height="124px"
          alt="Elipse"
          className={classes.OpportunytyEllipseFilters}
        />
        <StyledCard>
          <Typography variant="h5">{t('explore:explore')}</Typography>
          <SearchBox>
            <Typography variant="body1">{t('explore:search')}</Typography>
            <SearchIcon />
          </SearchBox>
          {items.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <StyledItem key={index}>
              <Typography variant="overline">{item}</Typography>
              <ExpandMoreIcon />
            </StyledItem>
          ))}
          <StyledTypographyBox>
            <Typography variant="button">{t('common:save_view')}</Typography>
          </StyledTypographyBox>
        </StyledCard>
      </FiltersContainer>
    );
}

export default HowItWorksFilters