import styled from 'styled-components'
import {Box} from '@mui/material'
import {makeStyles} from '@mui/styles'
import { deviceSizes} from '@app/config/devices';
import Card from '../../../../atoms/Card'

export const Container = styled.div`
  position: relative;
`;
export const StyledCard = styled(Card)`
  margin-left: 50px;  
  @media (max-width: ${deviceSizes.sm}) {
    margin-left: 0;
  }
`;

export const BoxContent = styled(Box)`
  padding:20px 40px 0px 40px;

`;
export const Item = styled(Box)`
  background-color: ${({ theme }) => theme.secondaryColor[100]};
  margin-bottom: 10px;
  display: flex;
  padding: 15px;
  align-items: center;
  border-radius: 6px;
  svg {
    fill: ${({ theme }) => theme.secondaryColor[600]};
    margin-right: 10px;
  }
  img {
    margin-right: 10px;
  }  
`;
export const DragItem = styled(Box)`
  width: 297px;
  height: 56px;
  background-color: ${({ theme }) => theme.primary.main};
  color: white;
  display: flex;
  padding: 15px;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 4.5px 6px rgba(0, 0, 0, 0.14), 0px 1.5px 8px rgba(0, 0, 0, 0.12),
    0px 2.5px 3px rgba(0, 0, 0, 0.2);
  svg {
    fill: #ffffff;
  }
  position: absolute;
  bottom: 280px;
  left: -200px;
  @media (max-width: ${deviceSizes.md}){
    display:none;
  }
`;
export const AccordionItem = styled(Box)`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.secondaryColor[150]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px 10px 40px;
  svg {
    fill: ${({ theme }) => theme.secondaryColor[600]};
  }
`;

export const useClasses = makeStyles({
  curserField: {
    boxShadow:
      '0px 3px 5px rgba(0, 0, 0, 0.14), 0px 0.5px 9px rgba(0, 0, 0, 0.12), 0px 1.5px 2.5px rgba(0, 0, 0, 0.2)',
    position: 'relative',
  },
  curserText: {
    position: 'absolute',
    bottom: '-10px',
    right: '-10px',
    padding: '5px',
    backgroundColor: '#616161',
    color: 'white',
    borderRadius: '6px',
  },
  Cursor: {
    position: 'absolute',
    bottom: '-15px',
    left: '0'
  },
  Cursor1: {
    position: 'absolute',
    left: '90px',
    top: '45px',
   
  }
});