import React from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Box } from '@mui/material';
import { StyledCard, Header, Content } from './style';
import Typography from '../../../../atoms/Typography';
import { useTranslation } from '../../../../../config/i18n';
import Radio from '../../../../atoms/Radio';

const AccountDetails = () => {
  const { t } = useTranslation('common', 'landing', 'howItWorks', 'wizard');

  return (
    <StyledCard>
      <Header>
        <Typography variant="h5">{t('wizard:fee_type.details')}</Typography>
        <Box display="flex" alignItems="center">
          <CheckCircleRoundedIcon color="primary" />
          <Typography variant="caption">{t('howItWorks:stripe_account_connected')}</Typography>
        </Box>
      </Header>
      <Content>
        <Typography variant="h5">{t('wizard:title')}</Typography>
        <Radio.RadioGroup aria-label="target" name="fee">
          <Radio
            label={t('howItWorks:accound_details_radiogroup.radio1')}
            inputProps={{
              'aria-label': 'primary Radio',
            }}
          />
          <Radio
            label={t('howItWorks:accound_details_radiogroup.radio2')}
            color="primary"
            checked
          />
          <Radio label={t('howItWorks:accound_details_radiogroup.radio3')} />
        </Radio.RadioGroup>
      </Content>
    </StyledCard>
  );
};

export default AccountDetails;
