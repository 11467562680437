import { gql } from '@apollo/client';


export const SAVE_SPACE_GROUP = gql`
  mutation saveSpaceGroup($name: String!, $spaceId: String!, $users: [String!], $groupId: String) {
    saveSpaceGroup(name: $name, spaceId: $spaceId, users: $users, groupId: $groupId) {
      id
      name
      stream_link
      users {
        id
        user_id
        name
        email
        avatar {
          path
        }
      }
    }
  }
`;

export const DELETE_SPACE_GROUP = gql`
  mutation deleteSpaceGroup($spaceId: String!, $groupId: String!) {
    deleteSpaceGroup(spaceId: $spaceId, groupId: $groupId) {
      id
      name
      stream_link
      users {
        id
        user_id
        name
        email
        avatar {
          path
        }
      }
    }
  }
`;

export const ADD_SPACE_GROUP_MEMBER = gql`
  mutation addSpaceGroupMember($name: String!, $email: String!, $groupId: String!) {
    addSpaceGroupMember (name: $name, email: $email, groupId: $groupId) {
      id
      name
      stream_link
      users {
        id
        user_id
        name
        email
        avatar {
          path
        }
      }
    }
  }
`;

export const DELETE_SPACE_USER = gql`
  mutation deleteSpaceUser($spaceId: String!, $memberIds: [String!]!) {
    deleteSpaceUser(spaceId: $spaceId, memberIds: $memberIds) {
      id
      name
      stream_link
      users {
        id
        user_id
        name
        email
        avatar {
          path
        }
      }
    }
  }
`;
