import React from 'react';
import { Box, Divider } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import {
  Container,
  BandCamp,
  Header,
  BandCampContent,
  BlackShade,
  JaneSmith,
  ButtonBox,
  useClasses
} from './style';
import Typography from '../../../../atoms/Typography';
import Image from '../../../../atoms/Image'
import { useTranslation } from '../../../../../config/i18n';
import Button from '../../../../atoms/Button'


const JaneSmithBandCamp = () => {

const { t } = useTranslation('common', 'landing', 'howItWorks', 'applications');
const classes = useClasses()
    return (
      <Container>
        <BandCamp>
          <Header image="/images/home/background.png">
            <BlackShade>
              <Box>
                <Typography variant="overline" className="textColorWhite" gutterBottom>
                  {t('howItWorks:band_camp_date')}
                </Typography>
                <Typography variant="h5" className="textColorWhite" gutterBottom>
                  {' '}
                  {t('howItWorks:band_camp')} 2021
                </Typography>
              </Box>
            </BlackShade>
          </Header>
          <BandCampContent>
            <Box className={classes.Boxes}>
              <AccessAlarmIcon style={{ fill: '#BB181E' }} fontSize="small" />
              <Typography variant="body2" style={{ color: '#BB181E' }} gutterBottom>
                {t('howItWorks:band_camp_deadline')}
              </Typography>
            </Box>
            <Box className={classes.Boxes}>
              <AssignmentIcon fontSize="small" />
              <Typography variant="caption" gutterBottom>
                123 {t('applications:application_plural')}
              </Typography>
            </Box>
            <Box className={classes.Boxes}>
              <AccountBalanceWalletIcon fontSize="small" />
              <Typography variant="caption" gutterBottom>
                $ 5000,00
              </Typography>
            </Box>
          </BandCampContent>
        </BandCamp>
        <JaneSmith>
          <Box display="flex" p={2}>
            <Image width="48px" height="48px" src="/images/home/JaneSmith1.png" />
            <Box display="flex" flexDirection=" column" ml={1}>
              <Typography variant="subtitle1">Jane Smith</Typography>
              <Typography variant="body2">{t('applications:reviewer')}</Typography>
            </Box>
          </Box>
          <Divider />
          <ButtonBox>
            <Image
              src="/images/home/cursor1.png"
              width="18px"
              height="23px"
              alt="cursor"
              className={classes.Cursor}
            />
            <Button variant="text" color="primary">
              {t('applications:approve')}
            </Button>
            <Button className={classes.MiddleButton} variant="text" color="primary">
              {t('applications:pending')}
            </Button>
            <Button variant="text" color="secondary">
              {t('applications:decline')}
            </Button>
          </ButtonBox>
        </JaneSmith>
        <Image
          src="/images/home/Ellipse 47.png"
          width="196px"
          height="196px"
          alt="Ellipse"
          className={classes.Elipse}
        />
      </Container>
    );
}

export default JaneSmithBandCamp