import styled from 'styled-components'
import {Box} from "@mui/material"
import Card from '../../../../atoms/Card'

export const StyledCard = styled(Card)`
  width: 544px;
  position: absolute;
  left: 125px;
  bottom: 0;
  z-index: 1;
`;

export const Header = styled(Box)`
  padding: 50px 80px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.secondaryColor[150]};
  h5{
      margin-bottom: 15px;
  }
  svg{
    margin-right: 10px;
  }
`;

export const Content = styled(Box)`
  padding: 50px 80px;
  display: flex;
  flex-direction: column;
  h5 {
    margin-bottom: 15px;
  }
`;