import React from 'react';
import OfflinePinRoundedIcon from '@mui/icons-material/OfflinePinRounded';
import { Container, Content } from './style';
import { useTranslation } from '../../../../../config/i18n';
import Typography from '../../../../atoms/Typography';
import {colors} from '../../../../../config/colors'


const MessageSent = () => {
const { t } = useTranslation('howItWorks');
    return (
      <Container>
        <OfflinePinRoundedIcon />
        <Content>
          <Typography variant="overline" className="messageSent">
            {t('howItWorks:message_sent')}
          </Typography>
          <Typography variant="body2">{t('howItWorks:message_sent_to')}</Typography>
          <Typography variant="body2" color="primary" gutterBottom>
            Gulia
          </Typography>
          <Typography variant="caption" color="secondary">
            {' '}
            07:20 AM 09/03/21
          </Typography>
        </Content>
      </Container>
    );
}

export default MessageSent;