import styled from 'styled-components';
import { Box } from '@mui/material';
import {makeStyles} from '@mui/styles';
import { deviceSizes } from '@app/config/devices';
import Card from '../../../../atoms/Card';
import { colors } from '../../../../../config/colors';

export const StyledCard = styled(Card)`
  padding: 67px 77px;
  margin-left: 70px;
  @media (max-width: ${deviceSizes.md}) {
    padding: 45px 20px;
    margin: 40px;
  }
  @media (max-width: ${deviceSizes.sm}) {
    padding: 45px 20px;
    margin: 20px;
  }
  @media (max-width: ${deviceSizes.mobileL}) {
    padding: 45px 10px;
    margin: 10px;
  }
`;
export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
  align-items: center;
`;

export const Title = styled(Box)`
  color: ${({ theme }) => theme.secondaryColor[400]};
  border-bottom: 1px solid ${({ theme }) => theme.secondaryColor[150]};
  border-top: 1px solid ${({ theme }) => theme.secondaryColor[150]};
  padding: 10px 25px 10px 0;
  span {
    padding: 10px 25px;
  }
`;

export const Item = styled(Box)`
  && {
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.secondaryColor[150]};
  }
`;
export const ItemContent = styled(Box)`
width: 70%;
display: flex:
flex-direction: column;
margin: 0 15px;
h6{
  color: ${({ theme }) => theme.secondaryColor[600]}
}
`;

export const Rows = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

export const useClasses = makeStyles({
  All: {
    color: `${colors.secondaryColor[700]}`,
    borderBottom: `4px solid ${colors.primary.main}`,
  },
  Star: {
    margin: '0 5px',
  },
  GoldStar: {
    fill: '#FAB32B',
    margin: '0 5px',
  },
  IconsSize: {
    width: '15px',
    height: '15px',
  },
  Sites: {
    margin: '0 20px',
  },
});
