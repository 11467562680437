import React from 'react';
import PropTypes from 'prop-types';
import {StyledCard} from './style'

const Card = ({children, ...props}) => <StyledCard  {...props}>{children}</StyledCard>

Card.propTypes = {
    children: PropTypes.node.isRequired
}

export default Card