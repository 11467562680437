import React from 'react';
import { Box, InputAdornment } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { StyledCard, InputGroup, ArrowBox } from './style';
import Typography from '../../../../atoms/Typography';
import TextField from '../../../../atoms/TextField'
import { useTranslation } from '../../../../../config/i18n';
 import Spacer from '../../../../atoms/Spacer'

const EntryFee = () => {
    const { t } = useTranslation('common', 'landing', 'howItWorks', 'wizard', 'payment');
  return (
    <StyledCard>
      <Box display="flex" justifyContent="space-between" width={1}>
        <Typography variant="h5">{t('howItWorks:entry_fee')}</Typography>
        <Box>
          <DeleteRoundedIcon color="primary" />
          <KeyboardArrowDownRoundedIcon color="secondary" />
        </Box>
      </Box>
      <TextField label={t('wizard:title')} value={t('wizard:title')} />
      <TextField
        label={t('howItWorks:fee_description')}
        value={t('howItWorks:covers_cost_admin')}
        helperText={t('howItWorks:optional')}
      />
      <InputGroup>
        <TextField
          label={t('wizard:cost')}
          value="15"
          className="inputs"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ArrowBox>
                  <ArrowDropUpRoundedIcon color="secondary" />
                  <ArrowDropDownRoundedIcon color="secondary" />
                </ArrowBox>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label={t('payment:currency')}
          value="EUR"
          className="inputs"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDownRoundedIcon color="secondary" />
              </InputAdornment>
            ),
          }}
        />
      </InputGroup>
      <TextField
        label={t('howItWorks:no_of_places')}
        value="300"
        className="inputs"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ArrowBox>
                <ArrowDropUpRoundedIcon color="secondary" />
                <ArrowDropDownRoundedIcon color="secondary" />
              </ArrowBox>
            </InputAdornment>
          ),
        }}
      />
      <Spacer width="100%" height="1rem" />
    </StyledCard>
  );
};

export default EntryFee;
