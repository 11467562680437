import styled from 'styled-components';
import { Box} from '@mui/material';
import {makeStyles} from '@mui/styles';
import MuiCardMedia from '@mui/material/CardMedia';
import { rgba } from 'polished';
import { deviceSizes } from '@app/config/devices';
import Card from '../../../../atoms/Card';



export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;  
  @media(max-width: ${deviceSizes.md}) {
    display: none;
  }
`;

export const BandCamp = styled(Card)`
    width: 304px;
    position: absolute;
    right: 40px;
    top: -30px;
`;

export const BandCampContent = styled(Box)`
    padding: 20px;
    svg {
        margin-right: 10px;
        fill: ${({ theme }) => theme.secondaryColor[700]};
    }
    
`;

export const Header = styled(MuiCardMedia)`
  display: flex;
  flex-basis: 100%;
  border-bottom: 2px solid ${({theme}) => theme.primary.main};
`;

export const BlackShade = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 100%;
  .textColorWhite{
    color: #FFFFFF;
  }
  ${({ theme }) =>
    theme &&
    `
    background-color:${rgba(theme.common.black, 0.35)};
    `}

  background: linear-gradient(180deg, rgba(33, 33, 33, 0) 0%, rgba(49, 47, 47, 0.75) 100%), rgba(33, 33, 33, 0.35);
`;

export const JaneSmith = styled(Card)`
  width: 275px;
  position: absolute;
  bottom: -40px;
  left: 140px;
  z-index: 1;
  filter: drop-shadow(-4px -8px 16px rgba(0, 0, 0, 0.25));
`;
export const ButtonBox = styled(Box)`
  position: relative;

  .MiddleButton {
    background-color: ${({ theme }) => theme.red[50]};
    border-radius: 0;
  }
  
`;

export const useClasses = makeStyles({
  Elipse: {
    position: 'absolute',
    left: '235px',
    bottom: '-180px',
    [`@media(max-width: ${deviceSizes.lg})`]: {
      left: '170px',
    },
  },
  Cursor: {
    position: 'absolute',
    zIndex: 1,
    left: '50px',
    top: '20px',
  },
  Boxes: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  MiddleButton: {
    backgroundColor: '#FFD4D4',
    borderRadius: 0,
  },
});