import styled from 'styled-components';
import TextField from '@app/components/atoms/TextField';
import {Grid} from '@mui/material'
import { deviceSizes } from '@app/config/devices';

export const SubFooterContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.secondaryColor[700]};
  color: ${({ theme }) => theme.white};
  padding: 0 150px;
  .mainContainer {
    align-items: center;
    justify-content: space-between;
  }
  .locales {
    border-right: 1px solid ${({ theme }) => theme.secondaryColor[600]};
    padding: 0.5rem;
  }
  @media (max-width: ${deviceSizes.md}) {
    padding: 0 50px;
  }
  @media (max-width: ${deviceSizes.sm}) {
    padding: 0 10px;
    .mainContainer {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }
  @media (max-width: ${deviceSizes.sm}) {
    .currency{
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }
    .copyright{
      text-align: center;
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

export const StyledTextField = styled(TextField)`
  && {
    
    margin: 0;
    span {
      color: white;
    }
    &:hover {
      border: none;
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .MuiSelect-outlined.MuiSelect-outlined {
      padding: 0;
    }

    
  }
  svg {
    fill: white;
    margin-left: 5px;
  }
`;

export const CurrencyBox = styled(Grid)`
  padding: 10px;

  border-left: 1px solid ${({ theme }) => theme.secondaryColor[600]};
  border-right: 1px solid ${({ theme }) => theme.secondaryColor[600]};
  @media (max-width: ${deviceSizes.sm}) {
    border-left: none;
    border-right: none;
  }
`;
export const LanguagesBox = styled(Grid)`
  padding: 10px;
  border-right: 1px solid ${({ theme }) => theme.secondaryColor[600]};
  @media (max-width: ${deviceSizes.sm}){
    border-right: none;
  }
`;
