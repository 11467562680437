import React from 'react';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import TextFieldsRoundedIcon from '@mui/icons-material/TextFieldsRounded';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { Container, StyledCard, BoxContent, Item, AccordionItem, DragItem, useClasses } from './style';
import Typography from '../../../../atoms/Typography';
import { useTranslation } from '../../../../../config/i18n';
import Image from '../../../../atoms/Image';
import Spacer from '../../../../atoms/Spacer'

const FormElemets = () => {
  const { t } = useTranslation('common', 'landing', 'howItWorks', 'wizard');
  const classes = useClasses()

  return (
    <Container>
      <DragItem>
        <TextFieldsRoundedIcon />
        <Typography variant="subtitle1">{t('wizard:text')}</Typography>
        <Image
          src="/images/home/GrabCursor.png"
          width="18px"
          height="23px"
          alt="cursor"
          className={classes.Cursor1}
        />
      </DragItem>
      <StyledCard>
        <Spacer width="100%" height="1rem" />

        <BoxContent>
          <Typography variant="h5">{t('howItWorks:form_elements')}</Typography>
        </BoxContent>

        <AccordionItem>
          <Typography variant="overline">{t('howItWorks:structural_elements')}</Typography>
          <KeyboardArrowDownRoundedIcon />
        </AccordionItem>
        <BoxContent>
          <Item className={classes.curserField}>
            <Image
              src="/images/home/cursor1.png"
              width="18px"
              height="23px"
              alt="cursor"
              className={classes.Cursor}
            />

            <Typography className={classes.curserText} variant="caption">
              {t('howItWorks:headings_descriptive')}
            </Typography>

            <TextFieldsRoundedIcon />
            <Typography variant="subtitle1">{t('wizard:text')}</Typography>
          </Item>
          <Item>
            <Image
              src="/images/home/divider.png"
              width="24px"
              height="24px"
              alt="divider"
              className="divider"
            />
            <Typography variant="subtitle1">{t('howItWorks:divider')}</Typography>
          </Item>
        </BoxContent>
        <AccordionItem>
          <Typography variant="overline">{t('howItWorks:user_info_questions')}</Typography>
          <KeyboardArrowDownRoundedIcon />
        </AccordionItem>
        <BoxContent>
          <Item>
            <AlternateEmailRoundedIcon />
            <Typography variant="subtitle1">{t('common:email')}</Typography>
          </Item>
          <Item>
            <PhoneRoundedIcon />
            <Typography variant="subtitle1">{t('wizard:tel')}</Typography>
          </Item>
          <Item>
            <EventRoundedIcon />
            <Typography variant="subtitle1">{t('howItWorks:date_&&_time')}</Typography>
          </Item>
          <Item>
            <LocationOnRoundedIcon />
            <Typography variant="subtitle1">{t('common:location')}</Typography>
          </Item>
        </BoxContent>
        <AccordionItem display="flex" justifyContent="space-between">
          <Typography variant="overline">{t('howItWorks:add_media')}</Typography>
          <KeyboardArrowDownRoundedIcon />
        </AccordionItem>
        <AccordionItem>
          <Typography variant="overline">{t('howItWorks:custom_questions')}</Typography>
          <KeyboardArrowDownRoundedIcon />
        </AccordionItem>
        <AccordionItem>
          <Typography variant="overline">{t('howItWorks:form_language')}</Typography>
          <KeyboardArrowDownRoundedIcon />
        </AccordionItem>
        <Spacer width="100%" height="2rem" />
      </StyledCard>
    </Container>
  );
};

export default FormElemets;
