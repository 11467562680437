import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/material';
import { StyledCard, Header, StyledBox, CardContent, useClasses } from './style';
import Typography from '../../../../atoms/Typography';
import TextField from '../../../../atoms/TextField';
import Button from '../../../../atoms/Button';
import { useTranslation } from '../../../../../config/i18n';
import {colors} from '../../../../../config/colors'

const NewMessage = () => {
  const { t } = useTranslation('common','howItWorks');
  const classes = useClasses()

  return (
    <StyledCard>
      <Header>
        <Typography variant="h6" color='secondary'>
          {t('howItWorks:new_messages')}
        </Typography>
        <DeleteIcon color="primary" fontSize="small" />{' '}
      </Header>
      <CardContent>
        <StyledBox>
          <Typography variant="body2">{t('howItWorks:hi_gulia')}</Typography>
        </StyledBox>
        <Box pr={3} pb={5}>
          {' '}
          <Typography variant="body2">{t('howItWorks:question_swedish_folk_music')}</Typography>
        </Box>
      </CardContent>
      <Header>
        <TextField
          disabled
          label={t('common:to')}
          defaultValue="Gulia"
          variant="outlined"
          className={classes.InputStyle}
        />
        <Button color="primary" className={classes.SentBtn}>
          {t('howItWorks:send')}
        </Button>
      </Header>
    </StyledCard>
  );
};

export default NewMessage;
