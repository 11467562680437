import React from 'react';
import PropTypes from 'prop-types';

import { TextField as MuiTextField } from '@mui/material';

const TextField = ({ variant, label, margin, children, fullWidth, type, multiline, ...props }) => (
  <MuiTextField
    label={label}
    fullWidth={fullWidth}
    margin={margin}
    variant={variant}
    type={type}
    multiline={multiline}
    {...props}
  >
    {children}
  </MuiTextField>
);

TextField.propTypes = {
  variant: PropTypes.string,
  margin: PropTypes.string,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  multiline: PropTypes.bool,
  children: PropTypes.node
};

TextField.defaultProps = {
  margin: 'normal',
  fullWidth: true,
  variant: 'outlined',
  label: undefined,
  type: undefined,
  multiline: false,
  children: undefined,
};

export default TextField;
