import styled from 'styled-components';
import {Box} from '@mui/material';
import { deviceSizes } from '@app/config/devices';
import Card from '../../../../atoms/Card';

export const Container = styled.div`
  position: relative;
  z-index: 1;
  @media (max-width: ${deviceSizes.laptopL}) {
    margin: 0 40px;
  }
  @media (max-width: ${deviceSizes.mobileL}) {
    margin: 0 20px;
  }
`;
export const StyledCard = styled(Card)`
  padding: 40px;
  .smallimages {
    margin-right: 10px;
  }
  svg {
    margin-right: 25px;
    margin-left: 5px;
  }
  @media (max-width: ${deviceSizes.tablet}) {
   
    svg {
      margin-right: 0;
      margin-left: 0;
    }
  }
  @media (max-width: ${deviceSizes.sm}) {
    padding: 10px;
    svg {
      margin-right: 0;
      margin-left: 0;
    }
  }
`;
export const StyledHeder = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  @media (max-width: ${deviceSizes.mobileM}) {
    flex-direction: column;
    align-items: flex-start;
    button{
      padding: 5px 0 0 0;
    }
  }
`;

export const ReviewersWithCursor = styled.div`
  @media (max-width: ${deviceSizes.sm}) {
    display:none
  }
`;

export const StaticMenu = styled.div`
  .Cursor {
    position: absolute;
    right: 75px;
    bottom: 30px;
  }
`;

export const MenuBox = styled(Card)`
  position: absolute;
  padding: 15px;
  right: -120px;
  top: 70px;
  z-index: 10;
  filter: drop-shadow(-4px -8px 16px rgba(0, 0, 0, 0.25));
  .MuiBox-root {
    margin-bottom: 10px;
  }
  @media (max-width: ${deviceSizes.sm}){
    right:-10px;
    top: 50px;
  }
`;