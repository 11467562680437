import styled from 'styled-components'
import {Box} from '@mui/material'
import Card from '../../../../atoms/Card'

export const StyledCard = styled(Card)`
  position: absolute;
  width: 642px;
  padding: 40px;
  top: 90px;
  right: -200px;
  z-index: 10;
  filter: drop-shadow(-4px -8px 16px rgba(0, 0, 0, 0.25));
`;

export const InputGroup = styled(Box)`
    display: flex;
    justify-content: space-between;
    .inputs{
        width: 48%;
    }
`

export const ArrowBox = styled(Box)`
  display: flex;
  flex-direction: column;
  .inputs {
    width: 48%;
  }
`;
