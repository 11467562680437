import React from 'react';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import {
  Container,
  StyledCard,
  Cardheaer,
  CardContent,
  StyledBox,
  StyledTypography,
  useClasses,
} from './style';
import Image from '../../../../atoms/Image';
import Typography from '../../../../atoms/Typography';
import RoundedButton from '../../../../atoms/RoundedButton';
import { useTranslation } from '../../../../../config/i18n';

const HowItWorksCard = () => {
  const { t } = useTranslation('common', 'landing', 'howItWorks');
  const classes = useClasses();

  return (
    <Container>
      <StyledCard>
        <Image
          src="/images/home/CardAvatar.png"
          width="64px"
          height="64px"
          alt="Avatar"
          className="CardAvatar"
        />
        <Cardheaer>
          <Image src="/images/home/CardImage.png" width="100%" height="100%" alt="CardImage" />
        </Cardheaer>
        <CardContent>
          <Typography variant="overline" gutterBottom>
            {t('howItWorks:how_it_works_card.date')}
          </Typography>
          <Typography variant="h5" gutterBottom>
            {t('howItWorks:how_it_works_card.title')}
          </Typography>
          <StyledBox>
            <LibraryMusicIcon size="small" />
            <Typography variant="caption">
              {t('howItWorks:how_it_works_card.type_of_opportunity')}
            </Typography>
          </StyledBox>
          <StyledTypography variant="body2">
            {t('howItWorks:how_it_works_card.not_paid')}
          </StyledTypography>
        </CardContent>
      </StyledCard>
      <RoundedButton
        size="large"
        className={classes.Accepted}
        endIcon={<CheckCircleRoundedIcon color="primary" />}
      >
        {t('howItWorks:how_it_works_card.accepted')}
      </RoundedButton>
      <Image
        src="/images/home/Ellipse 33.png"
        width="110px"
        height="110px"
        alt="Ellipse"
        className={classes.Ellipse}
      />
    </Container>
  );
};

export default HowItWorksCard;
