import styled from 'styled-components';
import { Box } from '@mui/material';
import {makeStyles} from '@mui/styles';
import { deviceSizes } from '@app/config/devices';
import Card from '../../../../atoms/Card';

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 460px;
  filter: drop-shadow(-8px 16px 32px rgba(0, 0, 0, 0.25));
  position: absolute;
  z-index: 1;
  left: -150px;
  top: 20px;
  @media (max-width: ${deviceSizes.md}) {
    display: none;
  }  
 
`;

export const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.secondaryColor[150]};

  Button {
  }
  .inputField {
    width: 40%;
  }
`;

export const CardContent = styled.div`
  padding: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.secondaryColor[150]};
`;
export const StyledBox = styled(Box)`
  && {
    padding-bottom: 20px;
    border-right: 3px solid ${({ theme }) => theme.secondaryColor[150]};
  }
`;

export const useClasses = makeStyles({
  InputStyle: {
    width: '40%',
  },
  SentBtn: {
    width: '40%',
  },
  
});
