/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { Grid, Fab, Box, Divider } from '@mui/material';
import { useQuery } from '@apollo/client';
import SearchIcon from '@mui/icons-material/Search';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { useTranslation } from '../../../../config/i18n';
import Typography from '../../../atoms/Typography';
import HowItWorksFilters from './HowItWorksFiltres'
import HowItWorksCard from './HowItWorksCard';
import HowItWorksMessages from './HowItworksMessages'
import NewMessage from './NewMessage';
import MessageSent from './MessageSent';
import BandCamp from './BandCamp';
import FormElemets from './FormElemets';
import AccountDetails from './AccountDetails';
import EntryFee from './EntryFee';
import SymphonyOrchestra from './SymphonyOrchstra';
import JaneSmithBandCamp from './JaneSmithBandCamp';
import Applications from './Applications';
import Reviewers from './Reviewers';
import ExportEverything from './ExportEverything'
import {
  StyledInfoBox,
  ButtonGroupBox,
  useClasses,
  ImageBox,
  BoxWithEllipse,
  ImageBoxOverFlow,
  ExploreContainer,
} from './style';
import {SEARCH_OPPORTUNITY} from '../../../../apollo/queries/opportunities.query'
import FeaturedSlider from '../../common/FeaturedSlider'
import Button from '../../../atoms/Button';
import Image from "../../../atoms/Image"
import Spacer from '../../../atoms/Spacer'
import { redirectToRoute } from '../../../../helpers/redirect';
import {colors} from '../../../../config/colors'


const HowItWorks = () => {
const { t } = useTranslation(
  'common',
  'applications',
  'landing',
  'wizard',
  'explore',
  'payment',
  'howItWorks'
);
  const [play, setPlay] = useState(true);

  const { data: opportunitiesData } = useQuery(SEARCH_OPPORTUNITY, {
    variables: {
      first: 20,
      input: {
        upcoming: true,
      },
    },
  });

  const handleRegister = () => {
    redirectToRoute({}, 'register')
  }

  const { opportunitySearch: { data: opportunities = [] } = {} } = opportunitiesData || {};

  const classes = useClasses()

  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <ButtonGroupBox>
        {' '}
        <Fab className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()}>
          <ChevronLeftIcon fontSize="small" />
        </Fab>
        <Fab onClick={() => setPlay(!play)}>
          {!play ? <PlayArrowIcon fontSize="small" /> : <PauseIcon fontSize="small" />}
        </Fab>
        <Fab onClick={() => next()}>
          <ChevronRightIcon fontSize="small" />
        </Fab>
      </ButtonGroupBox>
    );
  };

  ButtonGroup.propTypes = {
    next: PropTypes.func,
    previous: PropTypes.func,
  };
  ButtonGroup.defaultProps = {
    next: () => {},
    previous: () => {}
  }

  const handleExploreOpportunities = () => {
    // opportunity_search
    redirectToRoute({}, 'opportunity_search');
  };

  useEffect(() => {
    if (window.innerWidth < 600) {     
      setPlay(false);
    }
  }, []);


 
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.GridStyle}>
        <FeaturedSlider
          className={classes.SliderStyle}
          autoPlay={play}
          arrows={false}
          customButtonGroup={<ButtonGroup />}
          opportunities={opportunities}
        />
      </Grid>
      <Grid container>
        <Grid item lg={4} md={4} />
        <Grid item lg={5} md={6} sm={10} xs={10}>
          <StyledInfoBox>
            <Box display="flex" alignItems="center" pb={1}>
              <MusicNoteIcon fontSize="small" color="primary" />
              <Typography variant="overline" color="primary" gutterBottom>
                {t('howItWorks:musician')}
              </Typography>
            </Box>
            <Typography gutterBottom variant="h2">
              {t('howItWorks:title_subtitle.step_0.title')}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {t('howItWorks:title_subtitle.step_0.sub_title')}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SearchIcon />}
              onClick={handleExploreOpportunities}
            >
              {t('explore_opportunities')}
            </Button>
          </StyledInfoBox>
        </Grid>
      </Grid>
      <Box className={classes.Spacer} />
      <Grid container className={classes.FiltersMainContainer}>
        <Grid item lg={4} md={4} sm={6}>
          <HowItWorksFilters />
        </Grid>
        <Grid item lg={8} md={8} sm={6} className={classes.FiltersTitles}>
          <StyledInfoBox width="70%">
            <Box display="flex" alignItems="center" pb={1}>
              <MusicNoteIcon fontSize="small" color="primary" />
              <Typography variant="overline" color="primary" gutterBottom>
                {t('howItWorks:musician')}
              </Typography>
            </Box>
            <Typography gutterBottom variant="h2">
              {t('howItWorks:title_subtitle.step_1.title')}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {t('howItWorks:title_subtitle.step_1.sub_title')}
            </Typography>
          </StyledInfoBox>
          <Spacer height="5rem" width="100%" />
          <Box>
            <Image
              src="/images/home/CardsGroup.png"
              alt="CardsGroup"
              width="100%"
              // height="350px"
              className={classes.CardsGroup}
            />
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.Spacer} />
      <Grid container className={classes.HowItWorksCardMainContaniner}>
        <Grid item lg={5} md={5} sm={6} xs={10}>
          <StyledInfoBox>
            <Box display="flex" alignItems="center" pb={1}>
              <MusicNoteIcon fontSize="small" color="primary" />
              <Typography variant="overline" color="primary" gutterBottom>
                {t('howItWorks:musician')}
              </Typography>
            </Box>
            <Typography gutterBottom variant="h2">
              {t('howItWorks:title_subtitle.step_2.title')}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {t('howItWorks:title_subtitle.step_2.sub_title')}
            </Typography>
          </StyledInfoBox>
        </Grid>
        <Grid item lg={3} md={3} sm={5} xs={10}>
          <HowItWorksCard />
        </Grid>
      </Grid>
      <Box className={classes.Spacer} />
     
      <Grid container className={classes.MessagesMainBox}>
        <Grid item lg={6} md={7} sm={12} xs={12}>
          <HowItWorksMessages />
        </Grid>
        <Grid item lg={6} md={5} sm={12} xs={12}>
          <StyledInfoBox className={classes.MessagesStyledBox}>
            <Box display="flex" alignItems="center" pb={1}>
              <MusicNoteIcon fontSize="small" color="primary" />
              <Typography variant="overline" color="primary" gutterBottom>
                {t('howItWorks:musician')}
              </Typography>
            </Box>
            <Typography gutterBottom variant="h2">
              {t('howItWorks:title_subtitle.step_3.title')}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {t('howItWorks:title_subtitle.step_3.sub_title')}
            </Typography>
          </StyledInfoBox>
          <ImageBox>
            {' '}
            <MessageSent />
            <NewMessage />
            <Image
              src="/images/home/ThreeRedCircles.png"
              width="200px"
              height="161px"
              className={classes.ThreeRedCircles}
            />
          </ImageBox>
        </Grid>
      </Grid>
      <Box className={classes.Spacer} />
      <Divider style={{ width: '90%', margin: '0 auto' }} />
      <Box className={classes.Spacer} />
      <Grid container justifyContent="center">
        <Grid item lg={4} md={4} sm={9}>
          <StyledInfoBox width="80%">
            <Box display="flex" alignItems="center" pb={1}>
              <AssignmentIcon fontSize="small" color="primary" style={{ marginRight: '5px' }} />
              <Typography variant="overline" color="primary" gutterBottom>
                {t('howItWorks:organisers')}
              </Typography>
            </Box>
            <Typography gutterBottom variant="h2">
              {t('howItWorks:title_subtitle.step_4.title')}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {t('howItWorks:title_subtitle.step_4.sub_title')}
            </Typography>
          </StyledInfoBox>
        </Grid>
        <Grid item lg={4} md={4}>
          <ImageBox>
            <Image
              width="525px"
              height="430px"
              src="/images/home/MusicBand.png"
              alt="MusicBand"
              className={classes.MusicBand}
            />
          </ImageBox>
        </Grid>
      </Grid>
      <Spacer height="3rem" width="100%" />
      <Grid container>
        <Grid item lg={12} md={12}>
          <BoxWithEllipse>
            <StyledInfoBox>
              <Box display="flex" alignItems="center" pb={1}>
                <AssignmentIcon fontSize="small" style={{ color: '#FFFFFF', marginRight: '5px' }} />
                <Typography variant="overline" gutterBottom>
                  {t('howItWorks:organisers')}
                </Typography>
              </Box>
              <Typography gutterBottom variant="h2">
                {t('howItWorks:title_subtitle.step_5.title')}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {t('howItWorks:title_subtitle.step_5.sub_title')}
              </Typography>
            </StyledInfoBox>
          </BoxWithEllipse>
          <Grid container direction={'row'} className={classes.ContainerOverellipse}>
            <Grid item lg={6} md={6}>
              <BandCamp />
            </Grid>
            <Grid item lg={4} md={5} sm={9} xs={10}>
              {' '}
              <FormElemets />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.Spacer} />
      <Grid container className={classes.PaymentInfoBox}>
        <Grid item lg={2} md={3} sm={10} xs={10}>
          <StyledInfoBox>
            <Box display="flex" alignItems="center" pb={1} mt={5}>
              <AssignmentIcon fontSize="small" color="primary" />
              <Typography variant="overline" gutterBottom>
                {t('howItWorks:organisers')}
              </Typography>
            </Box>
            <Typography gutterBottom variant="h2">
              {t('howItWorks:title_subtitle.step_6.title')}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {t('howItWorks:title_subtitle.step_6.sub_title')}
            </Typography>
          </StyledInfoBox>
        </Grid>
        <Grid item lg={8} md={8}>
          <ImageBoxOverFlow>
            <AccountDetails />
            <EntryFee />
            <Image
              src="/images/home/Ellipse 33.png"
              width="174px"
              height="174px"
              className={classes.Ellipse33}
            />
          </ImageBoxOverFlow>
        </Grid>
      </Grid>
      <Spacer height="5rem" width="100%" />
      <Grid item lg={8} md={10} sm={12} xs={12}>
        <SymphonyOrchestra />
      </Grid>
      <Box className={classes.Spacer} />
      <Grid container className={classes.ReviewMainContainer}>
        <Grid item lg={5} md={6}>
          <StyledInfoBox width="80%">
            <Box display="flex" alignItems="center" pb={1}>
              <AssignmentIcon fontSize="small" color="primary" />
              <Typography variant="overline" gutterBottom>
                {t('howItWorks:organisers')}
              </Typography>
            </Box>
            <Typography gutterBottom variant="h2">
              {t('howItWorks:title_subtitle.step_7.title')}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {t('howItWorks:title_subtitle.step_7.sub_title')}
            </Typography>
          </StyledInfoBox>
        </Grid>
        <Grid item lg={5} md={6}>
          <JaneSmithBandCamp />
        </Grid>
      </Grid>

      <Box className={classes.Spacer} />
      <Grid container className={classes.ReviewApplications}>
        <Grid item lg={8} md={11} sm={12} xs={12}>
          <Applications />
        </Grid>
      </Grid>

      <Box className={classes.Spacer} />
      <Grid container className={classes.JuriMainContenaier}>
        <Grid item lg={8} md={10} sm={12} xs={12}>
          <StyledInfoBox className={classes.JuriInfoBox}>
            <Box display="flex" alignItems="center" pb={1}>
              <AssignmentIcon fontSize="small" color="primary" />
              <Typography variant="overline" gutterBottom>
                {t('howItWorks:organisers')}
              </Typography>
            </Box>
            <Typography gutterBottom variant="h2">
              {t('howItWorks:title_subtitle.step_8.title')}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {t('howItWorks:title_subtitle.step_8.sub_title')}
            </Typography>
          </StyledInfoBox>
          <Spacer height="4rem" width="100%" />
        </Grid>
        <Grid item lg={8} md={9} sm={10} xs={12}>
          <Reviewers />
          <ImageBox>
            <Image
              src="/images/home/Ellipse 47.png"
              width="174px"
              height="174px"
              className={classes.EllipseReviewers}
            />
          </ImageBox>
        </Grid>
      </Grid>
      <Spacer width="100%" height="7rem" />
      <Grid container justifyContent="center">
        <Grid item lg={4} md={4} sm={5}>
          <StyledInfoBox>
            <Box display="flex" alignItems="center" pb={1}>
              <AssignmentIcon fontSize="small" color="primary" />
              <Typography variant="overline" gutterBottom>
                {t('howItWorks:organisers')}
              </Typography>
            </Box>
            <Typography gutterBottom variant="h2">
              {t('howItWorks:title_subtitle.step_9.title')}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {t('howItWorks:title_subtitle.step_9.sub_title')}
            </Typography>
          </StyledInfoBox>
        </Grid>
        <Grid item lg={4} md={4} sm={5}>
          <ImageBox>
            <Image
              src="/images/home/ExportRedImages.png"
              width="211px"
              height="161px"
              className={classes.ExportRedImages}
            />
          </ImageBox>
        </Grid>
      </Grid>
      <Box className={classes.Spacer} />
      <Grid container justifyContent="flex-end">
        <Grid item lg={11} md={11} sm={12} xs={12}>
          <ExportEverything />
        </Grid>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ExploreContainer background={colors.secondaryColor[100]}>
          <Typography variant="h2"> {t('howItWorks:title_subtitle.step_10.title')}</Typography>
          <Typography variant="subtitle1">
            {t('howItWorks:title_subtitle.step_10.sub_title')}
          </Typography>
          <Button onClick={handleRegister} color="primary">
            {t('howItWorks:register_for_free')}
          </Button>
        </ExploreContainer>
        <ExploreContainer background={colors.primary.main}>
          <Typography variant="h2" className="textColorWhite">
            {t('howItWorks:title_subtitle.step_11.title')}
          </Typography>
          <Typography variant="subtitle1" className="textColorWhite">
            {t('howItWorks:title_subtitle.step_11.sub_title')}
          </Typography>
          <Image width="268px" height="63px" src="/images/jmi-logo.png" />
        </ExploreContainer>
      </Grid>
    </>
  );
};

HowItWorks.propTypes = {};

export default HowItWorks;
