import styled from 'styled-components';
import { Grid, Box } from '@mui/material';
import {makeStyles} from '@mui/styles';
import { devices, deviceSizes } from '../../../../config/devices';

export const Container = styled(Grid)`
  && {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
    width: 100%;

    @media (max-width: ${deviceSizes.mobileL}) {
      margin-top: 2rem;
      margin-bottom: 2em;
    }
  }

  text-align: center;
`;

export const ButtonGroupBox = styled.div`
  position: absolute;
  top: 5px;
  right: 30px;
  button {
    width: 40px;
    height: 40px;
    background-color: #fa6363;
    color: white;
    border-radius: 50%;
    padding: 10px;
    box-shadow: none;
    margin: 5px;
    text-align: center;
    &:hover {
      background-color: ${({ theme }) => theme.red[500]};
    }
  }

  @media (max-width: ${deviceSizes.sm}) {
    display: none;
  }
`;

export const StyledInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  margin-left: ${({ marginLeft }) => marginLeft};
  Button {
    width: fit-content;
    margin-top: 20px;
  }
  @media (max-width: ${deviceSizes.md}) {
    margin-left: 40px;
  }
`;

export const ImageBox = styled(Box)`
  && {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
  }
`;

export const ImageBoxOverFlow = styled(Box)`
  && {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    overflow-x: clip;
    @media (max-width: ${deviceSizes.md}) {
      display: none;
    }
  }
`;

export const ExploreContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 524px;
  flex-direction: column;
  .textColorWhite{
    color: #FFFFFF;
  }
  background-color: ${({ background }) => background};
  h6 {
    width: 41%;
    margin: 40px 0;
    text-align: center;
  }
  @media (max-width: ${deviceSizes.sm}) {
    h6 {
      width: 60%;
      text-align: center;
    }
    h2{
      text-align: center;
    }
  }
`;

export const BoxWithEllipse = styled.div`
  margin-left: 70px;
  padding-top: 300px;
  padding-left: 65px;
  height: 1020px;
  width: 1020px;
  background-image: url('/images/home/GroupEllipse.png');
  span,h2, h6 {
    color: #FFFFFF
  } 
  @media (max-width: ${deviceSizes.lg}) {
    padding-top: 190px;
    padding-left: 130px;
    margin-left: 20px;
    height: 900px;
    width: 900px;
    background-size: cover;
    h6 {
      width: 60%;
    }
  }
  @media (max-width: ${deviceSizes.md}) {
    height: 750px;
    width: 750px;
  }
  @media (max-width: ${deviceSizes.tablet}) {
    height: 570px;
    width: 570px;
    padding-top: 120px;
  }
  @media (max-width: ${deviceSizes.sm}) {
    background-image: none;
    height: 400px;
    width: 100%;
    padding: 40px 0px;
    margin-left: 0;
    background-color: ${({ theme }) => theme.primary.main};
  }
`;

export const useClasses = makeStyles({
  SliderStyle: {
    padding: '5rem',
    [`@media ${devices.xs}`]: {
      padding: '5rem 0 5rem 0',
    },
  },
  GridStyle: {
    marginTop: '-225px',
    // [`@media ${devices.xs}`]: {
    //   marginTop: '-440px',
    // },
  },
  FiltersMainContainer: {
    [`@media(max-width: ${deviceSizes.sm})`]: {
      flexDirection: 'column-reverse',
    },
  },
  FiltersTitles: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [`@media(max-width: ${deviceSizes.md})`]: {
      justifyContent: 'center',
    },
  },
  CardsGroup: {
    [`@media(max-width: ${deviceSizes.md})`]: {
      display: 'none',
    },
  },
  Spacer: {
    width: '100%',
    height: '7rem',
    [`@media(max-width: ${deviceSizes.sm})`]: { height: '3rem ' },
  },
  HowItWorksCardMainContaniner: {
    justifyContent: 'center',
    alignItems: 'center',
    [`@media(max-width: ${deviceSizes.md})`]: {
      justifyContent: 'flex-start',
    },
  },
  ThreeRedCircles: {
    position: 'absolute',
    top: '300px',
    right: '315px',
    [`@media(min-width: ${deviceSizes.lg}) and (max-width: ${deviceSizes.laptopL})`]: {
      right: '215px',
    },
    [`@media(max-width: ${deviceSizes.lg})`]: {
      display: 'none',
    },
  },
  MessagesMainBox: {
    [`@media(max-width: ${deviceSizes.mobileL})`]: {
     flexDirection: 'column-reverse',
     flexWrap: 'nowrap',
     marginTop: '40px'
    },
  },
  MessagesStyledBox: {
    width: '40%',
    marginLeft: '50px',
    marginTop: '80px',
    [`@media(max-width: ${deviceSizes.md})`]: {
      width: '70%',
      marginLeft: '40px',
      marginTop: '30px',
    },
  },
  MusicBand: {
    position: 'absolute',
    top: '40px',
    left: '-40px',

    [`@media(max-width: ${deviceSizes.laptopL})`]: {
      top: '35px',
      left: '50px',
    },
    [`@media(max-width: ${deviceSizes.lg})`]: {
      top: '-30px',
      left: '-50px',
    },
    [`@media(max-width: ${deviceSizes.laptop})`]: {
      display: 'none',
    },
  },
  ContainerOverellipse: {
    marginTop: '-500px',
    justifyContent: 'center',
    [`@media(max-width: ${deviceSizes.md})`]: {
      marginTop: '-350px',
    },
    [`@media(max-width: ${deviceSizes.tablet})`]: {
      marginTop: '-170px',
    },
    [`@media(max-width: ${deviceSizes.sm})`]: {
      marginTop: '-90px',
    },
  },
  LargeElipseBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  Ellipse33: {
    position: 'absolute',
    top: '350px',
    left: '440px',
    [`@media(max-width: ${deviceSizes.lg})`]: {
      left: '170px',
    },
  },
  PaymentInfoBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    [`@media(max-width: ${deviceSizes.md})`]: {
      justifyContent: 'flex-start',
    },
  },
  ReviewMainContainer: {
    justifyContent: 'flex-end',
    [`@media(max-width: ${deviceSizes.lg})`]: {
      marginLeft: '40px',
    },
    [`@media(max-width: ${deviceSizes.md})`]: {
      marginLeft: '0',
    },
  },
  ReviewApplications: {
    justifyContent: 'center',
    [`@media(max-width: ${deviceSizes.lg})`]: {
      justifyContent: 'flex-start',
    },
  },
  JuriMainContenaier: {
    justifyContent: 'center',
    [`@media(max-width: ${deviceSizes.md})`]: {
      justifyContent: 'flex-start',
    },
  },
  JuriInfoBox: {
    width: '47%',
    [`@media(max-width: ${deviceSizes.md})`]: {
      width: '80%',
    },
  },
  ExportRedImages: {
    [`@media(max-width: ${deviceSizes.sm})`]: {
      display: 'none',
    },
  },
  EllipseReviewers: {
    position: 'absolute',
    top: '-214px',
    right: '-85px',
    [`@media(max-width: ${deviceSizes.sm})`]: {
      display: 'none',
    },
  },
  // ExportEverythingTable: {
  //   justifyContent: 'flex-end',
  //   [`@media(max-width: ${deviceSizes.sm})`]: {
  //     padding: '10px !important',
  //   },
  // },
});
