/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import Landing from '@templates/Landing';
// import HeaderWithSidebar from '@templates/HeaderWithSidebar';
import HomeInfo from '@organisms/common/HomeInfo';
import HowItWorks from '@organisms/auth/HowItWorks';

import { useQuery } from '@apollo/client';
import { USER_QUERY } from '@app/apollo/queries';
// import VersionModal from '@organisms/common/VersionModal'
import { goToDashboard, isUser, redirectToRoute, isOrganiser } from '@app/helpers';
import { OK } from '@app/constants/guard-code';
import { useUser } from '../../helpers/hooks/useUser';

const Home =  () => {
  // const [open, setOpen] = React.useState(true);
  const { data, loading } =  useQuery(USER_QUERY);
 
 if (loading) {
   return <div/>
 } 
 if ( data) {
   return redirectToRoute({}, isOrganiser(data.me) ? 'my_opportunities' : 'opportunity_search');
 }



  return (
    <Landing secondSection={<HowItWorks />} title="">
      <HomeInfo />
      {/* <VersionModal open={open} setOpen={setOpen} /> */}
    </Landing>
  );
};

Home.guard = async (ctx, user) => {
  if (isUser(user)) {
    goToDashboard(ctx, user);
  }
  return OK;
};

export async function getInitialProps() {
  return {
    props: {
      namespacesRequired: [
        'landing',
        'common',
        'dashboard',
        'footer',
        'applications',
        'wizard',
        'explore',
        'payment',
        'howItWorks',
      ],
    },
  };
}

export default Home;
